import styled from 'styled-components';

interface StyledIconButtonProps {
  noRightMargin?: boolean;
  noCursor?: boolean;
}

export const StyledIconButton = styled.img<StyledIconButtonProps>`
  cursor: ${(props) => (props.noCursor ? 'default' : 'pointer')};
  height: 20px;
  margin-right: ${(props) => (props.noRightMargin ? '0px' : '5px')};
`;
