import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
    body {
    *, *::before, *::after {
        box-sizing: border-box;
    }
    font-family: Arial, Helvetica, sans-serif;
}`;

export default GlobalStyles;
