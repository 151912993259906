import {
  StyledModificationOptionsContainer,
  StyledTaskContainer,
  StyledTaskTitle,
} from './Styles';
import newIcon from './../../../assets/icons/newIcon.png';
import undoComplete from './../../../assets/icons/undo-complete.png';
import complete from './../../../assets/icons/complete.png';
import trash from './../../../assets/icons/trash.png';
import edit from './../../../assets/icons/edit.png';
import checked from './../../../assets/icons/checked.png';
import unchecked from './../../../assets/icons/unchecked.png';
import { useCustomContext } from '../../../hooks/useCustomContext';
import { GenericTaskFormContext } from '../../../contexts/GenericTaskFormContext';
import IconButton from '../IconButton/IconButton';
import { useState } from 'react';
import { SelectedTasksContext } from '../../../contexts/SelectedTasksContext';
import isScheduledToday from '../../../helpers/scheduling';
import { selectSubTasks } from '../../../features/subTasks/subTasksSlice';
import { useAppSelector } from '../../../store/store';
import { selectTasks } from '../../../features/tasks/tasksSlice';

interface TaskContainerProps {
  taskId: string;
  title: string;
  parentTaskId?: string;
  completed?: boolean;
  subTask?: boolean;
  selectionMode?: boolean;
  className?: string;
}

const TaskContainer = ({
  taskId,
  title,
  parentTaskId,
  completed,
  subTask,
  selectionMode,
  className,
}: TaskContainerProps) => {
  const tasks = useAppSelector(selectTasks);
  const subTasks = useAppSelector(selectSubTasks);
  const isUsedToday = isScheduledToday(taskId, tasks, subTasks);
  const [selected, setSelected] = useState(selectionMode ? isUsedToday : false);
  const {
    setIsSubTask,
    handleCompleteItem: handleCompleteTask,
    handleIncompleteItem: handleIncompleteTask,
    handleEditButtonClicked,
    handleCreateSubTaskButtonClicked,
    handleDeleteItem: handleDeleteTask,
  } = useCustomContext(GenericTaskFormContext);
  const { addSelectedTask, removeSelectedTask } =
    useCustomContext(SelectedTasksContext);

  return (
    <StyledTaskContainer
      className={className}
      width={subTask === true ? '85%' : '100%'}
    >
      <StyledTaskTitle>{title}</StyledTaskTitle>
      <StyledModificationOptionsContainer>
        {completed ? (
          <IconButton
            onClick={() => {
              if (parentTaskId) {
                handleIncompleteTask({ taskId, parentTaskId });
              } else {
                handleIncompleteTask({ taskId });
              }
            }}
            src={undoComplete}
            alt="undo complete"
          />
        ) : selectionMode ? null : (
          <>
            <IconButton
              onClick={() => handleCompleteTask(taskId)}
              src={complete}
              alt="complete"
            />
            <IconButton
              onClick={() => {
                if (subTask) {
                  setIsSubTask(true);
                }
                handleEditButtonClicked(taskId);
              }}
              src={edit}
              alt="edit"
            />
            {!subTask && (
              <IconButton
                onClick={() => handleCreateSubTaskButtonClicked(taskId)}
                src={newIcon}
                alt="new"
              />
            )}
          </>
        )}
        {selectionMode ? (
          selected ? (
            <IconButton
              onClick={() => {
                removeSelectedTask(taskId);
                setSelected(false);
              }}
              src={checked}
              alt="selected task"
            />
          ) : (
            <IconButton
              onClick={() => {
                addSelectedTask(taskId);
                setSelected(true);
              }}
              src={unchecked}
              alt="unselected task"
            />
          )
        ) : null}
        {selectionMode ? null : (
          <IconButton
            onClick={() => handleDeleteTask(taskId)}
            src={trash}
            alt="trash"
            noRightMargin
          />
        )}
      </StyledModificationOptionsContainer>
    </StyledTaskContainer>
  );
};

export default TaskContainer;
