import Modal from '../../../components/Modal/Modal';
import {
  Option,
  OptionContainer,
  TaskOptionModalContainer,
  TaskOptionModalHeader,
} from './Styles';

interface TaskOptionModalProps {
  isOpen: boolean;
  onClose: () => void;
  leftButtonTitle: string;
  leftButtonClick: () => void;
  rightButtonTitle: string;
  rightButtonClick: () => void;
}

const TaskOptionModal = ({
  isOpen,
  onClose,
  leftButtonTitle,
  leftButtonClick,
  rightButtonTitle,
  rightButtonClick,
}: TaskOptionModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <TaskOptionModalContainer>
        <TaskOptionModalHeader>Would you like to:</TaskOptionModalHeader>
        <OptionContainer>
          <Option onClick={() => leftButtonClick()}>{leftButtonTitle}</Option>
          <Option onClick={() => rightButtonClick()}>{rightButtonTitle}</Option>
        </OptionContainer>
      </TaskOptionModalContainer>
    </Modal>
  );
};

export default TaskOptionModal;
