import { useCallback, useEffect } from 'react';
import { hydrateUserProjects } from '../../api/projects';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { selectUser } from '../../features/user/userSlice';
import { selectProjects } from '../../features/projects/projectsSlice';
import { Project } from '../../models/projects';
import ProjectContainer from './components/ProjectContainer/ProjectContainer';
import AddButton from './components/AddButton/AddButton';
import ProjectModal from '../components/ProjectModal/ProjectModal';
import TaskModal from '../components/TaskModal/TaskModal';
import PageContainer from '../components/PageContainer/PageContainer';
import { DraggableProjectContainer } from './Styles';
import { ProjectFormContext } from '../../contexts/ProjectFormContext';
import { useCustomContext } from '../../hooks/useCustomContext';
import { GenericTaskFormContext } from '../../contexts/GenericTaskFormContext';
import useReOrderable from '../../hooks/useReOrderable';

const ProjectsPage = () => {
  const dispatch = useAppDispatch();
  const { id } = useAppSelector(selectUser);
  const projects = useAppSelector(selectProjects);
  const { draggedItemId, handleDragStart, handleDragOver, handleDrop } =
    useReOrderable();

  const {
    onFormModalOpen: onProjectModalOpen,
    formModalOpen: projectModalOpen,
  } = useCustomContext(ProjectFormContext);

  const { formModalOpen: taskModalOpen } = useCustomContext(
    GenericTaskFormContext,
  );

  const getProjects = useCallback(() => {
    dispatch(hydrateUserProjects(id));
  }, [dispatch, id]);

  useEffect(() => {
    getProjects();
  }, [getProjects]);

  return (
    <PageContainer>
      {projects.length > 0 ? (
        [...projects]
          .sort((a, b) => a.order - b.order)
          .map((project: Project) =>
            !project.completed ? (
              <DraggableProjectContainer
                key={project.id}
                draggable
                onDragStart={(e) => handleDragStart(e, project.id)}
                onDragOver={(e) => handleDragOver(e, project.id)}
                onDrop={(e) => handleDrop(e, project.id)}
                grabbed={draggedItemId ? 'grabbing' : 'grab'}
              >
                <ProjectContainer
                  key={project.id}
                  userId={id}
                  title={project.title}
                  projectId={project.id}
                  description={project.description}
                />
              </DraggableProjectContainer>
            ) : null,
          )
      ) : (
        <div>You don't have any projects.</div>
      )}
      <AddButton isProject onClick={() => onProjectModalOpen()} />
      <ProjectModal isOpen={projectModalOpen} />
      <TaskModal isOpen={taskModalOpen} />
    </PageContainer>
  );
};

export default ProjectsPage;
