import { createSlice } from '@reduxjs/toolkit';
import { InitialHabitsState, RootState, Status } from '../RootState';
import {
  addUserHabit,
  deleteUserHabit,
  editUserHabit,
  hydrateUserHabits,
} from '../../api/habits';

const initialState: InitialHabitsState = {
  habits: [],
  status: Status.IDLE,
  error: null,
};

export const habitsSlice = createSlice({
  name: 'habits',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(hydrateUserHabits.fulfilled, (state, action) => {
      if (action.payload) {
        state.habits = action.payload;
        state.status = Status.SUCCEEDED;
      }
    });
    builder.addCase(addUserHabit.fulfilled, (state, action) => {
      if (action.payload) {
        state.habits.push(action.payload);
        state.status = Status.SUCCEEDED;
      }
    });
    builder.addCase(editUserHabit.fulfilled, (state, action) => {
      if (action.payload) {
        const { id } = action.payload;
        const habitIndex = state.habits.findIndex((habit) => habit.id === id);
        if (habitIndex !== -1) {
          state.habits[habitIndex] = action.payload;
          state.status = Status.SUCCEEDED;
        }
      }
    });
    builder.addCase(deleteUserHabit.fulfilled, (state, action) => {
      if (action.payload) {
        const habitIdToDelete = action.payload.id;
        state.habits = state.habits.filter(
          (habit) => habit.id !== habitIdToDelete,
        );
        state.status = Status.SUCCEEDED;
      }
    });
  },
});

export const selectHabits = (state: RootState) => state.habitsState.habits;

export default habitsSlice.reducer;
