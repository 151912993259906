import styled from 'styled-components';

export const StyledTaskContainer = styled.div<{ width: string }>`
  background-color: ${(props) => props.theme.colors.primaryTaskBackground};
  border-radius: 5px;
  width: ${(props) => props.width};
  padding: 5px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  text-align: left;
`;

export const StyledModificationOptionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const StyledTaskTitle = styled.div`
  font-size: 12px;
`;
