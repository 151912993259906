import styled from 'styled-components';

export const StyledWeeklyDayOption = styled.div<{ bg: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.bg};
  border: 1px solid ${(props) => props.theme.colors.primaryTextBoxBorder};
  font-size: ${({ theme }) => theme.colors.containers.primaryFontSize};
  height: 25px;
  width: 25px;
  padding: 5px;
  border-radius: 100px;
  cursor: pointer;
`;
