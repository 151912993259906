import { StyledInfoContainerHeader, StyledInfoContainerTitle } from './Styles';

interface StyledInfoContainerHeaderProps {
  title?: string;
  children?: React.ReactNode;
}

const InfoContainerHeader = ({
  title,
  children,
}: StyledInfoContainerHeaderProps) => {
  return (
    <StyledInfoContainerHeader>
      {title ? (
        <StyledInfoContainerTitle>{title}</StyledInfoContainerTitle>
      ) : null}
      {children}
    </StyledInfoContainerHeader>
  );
};

export default InfoContainerHeader;
