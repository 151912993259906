import PageContainer from '../components/PageContainer/PageContainer';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { selectHabits } from '../../features/habits/habitsSlice';
import { hydrateUserHabits } from '../../api/habits';
import { selectUser } from '../../features/user/userSlice';
import { hydrateUserTasks } from '../../api/tasks';
import { selectTasks } from '../../features/tasks/tasksSlice';
import { hydrateUserSubTasks } from '../../api/subTasks';
import { selectSubTasks } from '../../features/subTasks/subTasksSlice';
import UserCalendar from '../components/UserCalendar/UserCalendar';
import { getTimeTrackerData } from '../../api/timeTracker';
import { CalendarEvent } from './../components/UserCalendar/helpers/calendarEventGeneration';

const CalendarPage = () => {
  const dispatch = useAppDispatch();
  const { id } = useAppSelector(selectUser);
  const tasks = useAppSelector(selectTasks);
  const subTasks = useAppSelector(selectSubTasks);
  const habits = useAppSelector(selectHabits);
  const [timeTrackerData, setTimeTrackerData] = useState<CalendarEvent[]>([]);

  const getTrackerData = async () => {
    const trackerData = await getTimeTrackerData(id);
    console.log(trackerData);
    setTimeTrackerData(trackerData);
  };

  useEffect(() => {
    dispatch(hydrateUserTasks(id));
    dispatch(hydrateUserHabits(id));
    dispatch(hydrateUserSubTasks(id));

    getTrackerData();
  }, [dispatch, id]);

  return (
    <PageContainer>
      <UserCalendar
        timeTrackerData={timeTrackerData}
        habits={habits}
        tasks={tasks}
        subTasks={subTasks}
      />
    </PageContainer>
  );
};

export default CalendarPage;
