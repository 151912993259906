import { useAppSelector } from '../../../store/store';
import {
  selectUser,
  selectValidateRefreshTokenStatus,
} from '../../../features/user/userSlice';
import { Status } from '../../../features/RootState';
import { Navigate } from 'react-router-dom';

interface ProtectedRouteProps {
  children: React.ReactNode;
}

const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const { isAuthenticated } = useAppSelector(selectUser);
  const status = useAppSelector(selectValidateRefreshTokenStatus);

  if (status === Status.LOADING) {
    return <>{children}</>;
  }

  if (!isAuthenticated && status === Status.SUCCEEDED) {
    return <Navigate to={'/'} replace />;
  }

  return <>{children}</>;
};

export default ProtectedRoute;
