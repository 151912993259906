import { StyledIsEmptyMessage } from './Styles';

interface IsEmptyMessageProps {
  text: string;
}

const IsEmptyMessage = ({ text }: IsEmptyMessageProps) => {
  return <StyledIsEmptyMessage>{text}</StyledIsEmptyMessage>;
};

export default IsEmptyMessage;
