import styled from 'styled-components';
import { device } from '../../../themes/breakpoints';

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

export const ModalBox = styled.div<{ width: string }>`
  background-color: ${(props) => props.theme.colors.primaryModalBackground};
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: ${(props) => props.width};
  display: flex;
  justify-content: center;
  flex-direction: column;
  overflow: auto;

  @media ${device.md} {
    width: 90%;
    height: 50%;
    overflow: auto;
    justify-content: flex-start;
  }

  @media ${device.lg} {
    min-width: 50%;
  }

  @media ${device.xxl} {
    min-width: 40%;
  }
`;
