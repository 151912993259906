import styled from 'styled-components';

export const TaskOptionModalContainer = styled.div``;

export const TaskOptionModalHeader = styled.div`
  font-size: 18px;
`;

export const OptionContainer = styled.div`
  display: flex;
  margin-top: 30px;
  gap: 15px;
`;

export const Option = styled.button`
  flex: 1;
  font-size: 12px;
  padding: 10px;
  border: none;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  background-color: ${(props) => props.theme.colors.primary};
  border-radius: 10px;
  color: ${(props) => props.theme.colors.primaryText};
  cursor: pointer;
`;
