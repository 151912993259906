import { useCallback, useEffect, useState } from 'react';
import { hydrateUserHabits } from '../../api/habits';
import { useAppDispatch, useAppSelector } from '../../store/store';
import PageContainer from '../components/PageContainer/PageContainer';
import {
  Emoji,
  EmojiRainContainer,
  HabitsPageBodyContainer,
  HabitsPageBodyContentContainer,
  NewHabitButton,
} from './Styles';
import Habit from './components/Habit/Habit';
import HabitTemplatesContainer from './components/HabitTemplatesContainer/HabitTemplates';
import { selectHabits } from '../../features/habits/habitsSlice';
import { selectUser } from '../../features/user/userSlice';
import HabitModal from './components/HabitModal/HabitModal';
import { hydrateUserProjects } from '../../api/projects';
import { format } from 'date-fns';
import IsEmptyMessage from '../components/IsEmptyMessage/IsEmptyMessage';
import PopUpCalendar from '../components/PopUpCalendar/PopUpCalendar';
import { useCustomContext } from '../../hooks/useCustomContext';
import { HabitFormContext } from '../../contexts/HabitFormContext';
import { getUserHabitCompletions } from '../../api/habitCompletions';

const HabitsPage = () => {
  const dispatch = useAppDispatch();
  const { id } = useAppSelector(selectUser);
  const habits = useAppSelector(selectHabits);
  const [habitDate, setHabitDate] = useState(
    format(new Date(), 'MM/dd/yyyy').toString(),
  );
  const [calendarOpen, setCalendarOpen] = useState(false);
  const { formModalOpen, onFormModalOpen } = useCustomContext(HabitFormContext);
  const [isRainingEmojis, setRainingEmojis] = useState(false);
  const emojis = ['🎉', '🎊', '🥳'];

  const startEmojiRain = () => {
    setRainingEmojis(true);
    setTimeout(() => setRainingEmojis(false), 5000); // Stop after 5 seconds
  };

  // const defaultHabits = [
  //   {
  //     title: '📧 Morning Emails',
  //     estimatedTime: 15,
  //     dates: '8:00am - 8:15am Mon - Fri',
  //     completionScore: 89,
  //     streak: 50,
  //   },
  //   {
  //     title: '💪 Gym',
  //     estimatedTime: 60,
  //     dates: '8:00am - 8:30am Mon - Fri',
  //     completionScore: 96,
  //     streak: 76,
  //   },
  // ];

  const getUserData = useCallback(() => {
    dispatch(hydrateUserHabits(id));
    dispatch(getUserHabitCompletions(id));
    dispatch(hydrateUserProjects(id));
  }, [dispatch, id]);

  useEffect(() => {
    getUserData();
  }, [getUserData]);

  return (
    <PageContainer>
      <HabitsPageBodyContainer>
        <HabitsPageBodyContentContainer>
          <HabitTemplatesContainer />
          <NewHabitButton onClick={() => onFormModalOpen()}>
            + New Habit
          </NewHabitButton>
          <br />
          {habits.length > 0 ? (
            habits.map((habit) => (
              <Habit
                key={habit.id}
                id={habit.id}
                title={habit.title}
                estimatedTime={habit.estimatedTime}
                startDate={habit.startDate}
                setCalendarOpen={setCalendarOpen}
                habitCompletionCelebration={startEmojiRain}
              />
            ))
          ) : (
            <IsEmptyMessage text="You don't have any habits yet. Click the New Habit button above to create one."></IsEmptyMessage>
          )}
          <PopUpCalendar
            onClickDay={() => setCalendarOpen(false)}
            calendarOpen={calendarOpen}
            onChange={(date) => {
              if (date) {
                const dateWithoutTime = format(String(date), 'MM/dd/yyyy');
                setHabitDate(dateWithoutTime.toString());
              }
            }}
            showOnRight
          />
        </HabitsPageBodyContentContainer>
      </HabitsPageBodyContainer>
      {isRainingEmojis && (
        <EmojiRainContainer>
          {Array.from({ length: 200 }).map((_, index) => (
            <Emoji
              key={index}
              duration={Math.random() * 3 + 2}
              delay={Math.random() * 2}
              left={Math.random() * 100}
            >
              {emojis[Math.floor(Math.random() * emojis.length)]}
            </Emoji>
          ))}
        </EmojiRainContainer>
      )}
      <HabitModal isOpen={formModalOpen} habitDate={habitDate} />
    </PageContainer>
  );
};

export default HabitsPage;
