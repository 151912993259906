import Modal from '../Modal/Modal';
import TitledTextBox from '../TitledTextBox/TitledTextBox';
import { useCustomContext } from '../../../hooks/useCustomContext';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ProjectFormContext } from '../../../contexts/ProjectFormContext';
import { useEffect } from 'react';
import YellowButton from '../YellowButton/YellowButton';
import { ProjectModalFormValues } from '../../../models/formModalContexts';

interface ProjectModalProps {
  isOpen: boolean;
}

const ProjectModal = ({ isOpen }: ProjectModalProps) => {
  const {
    handleFormSubmitButtonClicked: handleProjectSubmitButtonClicked,
    editMode,
    fillFormOnEdit,
    onFormModalClose,
    selectedItemId: selectedProjectId,
  } = useCustomContext(ProjectFormContext);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm<ProjectModalFormValues>();

  const onSubmit: SubmitHandler<ProjectModalFormValues> = (data) => {
    handleProjectSubmitButtonClicked(data);
    reset();
  };

  useEffect(() => {
    fillFormOnEdit(setValue, reset);
  }, [editMode, selectedProjectId, reset, setValue]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onFormModalClose();
        reset();
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <TitledTextBox
          title={'Title'}
          label={'title'}
          register={register}
          required
        />
        <TitledTextBox
          title={'Description'}
          size={'large'}
          label={'description'}
          register={register}
        />
        <YellowButton editMode={editMode} />
      </form>
      {errors.title && errors.title.type === 'maxLength' && (
        <p style={{ fontSize: '12px', color: 'red', margin: 0 }}>
          - Maximum title length exceeded
        </p>
      )}
    </Modal>
  );
};

export default ProjectModal;
