import { StyledIconButton } from './Styles';

interface IconButtonProps {
  onClick: () => void;
  src: string;
  alt: string;
  noRightMargin?: boolean;
  noCursor?: boolean;
}

const IconButton = ({
  onClick,
  src,
  alt,
  noRightMargin = false,
  noCursor = false,
}: IconButtonProps) => {
  return (
    <StyledIconButton
      onClick={onClick}
      src={src}
      alt={alt}
      noRightMargin={noRightMargin}
      noCursor={noCursor}
    />
  );
};

export default IconButton;
