import PageContainer from '../components/PageContainer/PageContainer';

const Settings = () => {
  return (
    <PageContainer>
      <div>Settings</div>
    </PageContainer>
  );
};

export default Settings;
