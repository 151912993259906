import styled from 'styled-components';

export const StyledIconsContainer = styled.div``;

export const TaskAndSubTaskContainer = styled.div``;

export const StyledProjectBodyContainer = styled.div`
  text-align: left;
`;

export const StyledProjectDescription = styled.p`
  font-size: 12px;
`;

export const ScrollableTaskList = styled.div`
  overflow-y: scroll;
  max-height: calc(100vh - 130px);
  height: 100%;
`;
