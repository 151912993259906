import styled from 'styled-components';
import IconButton from '../../../components/IconButton/IconButton';

export const StyledHabit = styled.div`
  width: 100%;
  height: 50px;
  border: solid;
  border-color: ${({ theme }) => theme.colors.containers.primaryBorderColor};
  border-radius: 5px;
  margin-bottom: 15px;
  color: ${({ theme }) => theme.colors.containers.primaryTextColor};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  font-size: ${({ theme }) => theme.colors.containers.primaryFontSize};
`;

export const StyledHabitTitle = styled.div`
  flex: 2;
  display: flex;
  align-items: flex-start;
`;

export const StyledHabitDetailsContainer = styled.div`
  flex: 5;
  width: 100%;
  display: flex;
  justify-content: space-around;
`;

export const StyledHabitDetailText = styled.div``;

export const StyledHabitIconsContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`;

export const StyledCompleteLogo = styled(IconButton)`
  margin-right: 10px;
`;
