import {
  addDays,
  addMinutes,
  addMonths,
  addWeeks,
  addYears,
  endOfDay,
  endOfWeek,
  getDay,
  getHours,
  getMinutes,
  getSeconds,
  isAfter,
  isWithinInterval,
  parseISO,
  startOfWeek,
} from 'date-fns';
import { Habits } from '../../../../models/habits';
import { Tasks } from '../../../../models/Tasks';
import { SubTasks } from '../../../../models/subTasks';
import { dayNameToNumber } from '../../../../helpers/time';

export interface CalendarEvent {
  id: string;
  title: string;
  start: Date;
  end: Date;
}

export const generateCalendarEvents = (
  habits: Habits,
  tasks: Tasks,
  subTasks: SubTasks,
  start: Date,
  end: Date,
): CalendarEvent[] => {
  const events: CalendarEvent[] = [];

  habits.forEach((habit) => {
    const {
      id,
      title,
      startDate,
      intervalType,
      intervalCount,
      daysOfWeek,
      estimatedTime,
    } = habit;
    let eventDates: Date[] = [];

    switch (intervalType) {
      case 'daily': {
        eventDates = generateDailyDates(
          startDate,
          end,
          eventDates,
          intervalCount,
        );
        break;
      }
      case 'weekly':
        eventDates = generateWeeklyDates(
          daysOfWeek,
          startDate,
          end,
          eventDates,
          intervalCount,
        );
        break;
      case 'monthly': {
        eventDates = generateMonthlyDates(
          startDate,
          end,
          eventDates,
          intervalCount,
        );
        break;
      }
      case 'yearly': {
        eventDates = generateYearlyDates(
          startDate,
          end,
          eventDates,
          intervalCount,
        );
        break;
      }
    }

    eventDates
      .filter((date) => isWithinInterval(date, { start, end }))
      .forEach((date) => {
        const endDate = addMinutes(date, estimatedTime);
        events.push({
          id: `${id}-${date.toISOString()}`,
          title,
          start: date,
          end: endDate,
        });
      });
  });

  tasks.forEach((task) => {
    const { id, title, startDate, estimatedTime } = task;
    if (startDate) {
      if (isWithinInterval(parseISO(startDate), { start, end })) {
        const endDate = addMinutes(parseISO(startDate), estimatedTime);
        events.push({
          id: `${id}-${startDate}`,
          title,
          start: parseISO(startDate),
          end: endDate,
        });
      }
    }
  });

  subTasks.forEach((subTask) => {
    const { id, title, startDate, estimatedTime } = subTask;
    if (startDate) {
      if (isWithinInterval(parseISO(startDate), { start, end })) {
        const endDate = addMinutes(parseISO(startDate), estimatedTime);
        events.push({
          id: `${id}-${startDate}`,
          title,
          start: parseISO(startDate),
          end: endDate,
        });
      }
    }
  });

  return events;
};

const generateDailyDates = (
  startDate: string,
  end: Date,
  eventDates: Date[],
  intervalCount: number,
) => {
  let currentDate = parseISO(startDate);
  while (currentDate <= end) {
    eventDates.push(new Date(currentDate));
    currentDate = addDays(currentDate, intervalCount);
  }
  return eventDates;
};

const generateWeeklyDates = (
  daysOfWeek: string | null,
  startDate: string,
  end: Date,
  eventDates: Date[],
  intervalCount: number,
) => {
  if (daysOfWeek) {
    const daysOfWeekArray = daysOfWeek
      .split(',')
      .map((day) => dayNameToNumber(day));
    let currentWeekStart = startOfWeek(parseISO(startDate), {
      weekStartsOn: 1,
    });
    while (currentWeekStart <= end) {
      const currentWeekEnd = endOfWeek(currentWeekStart, {
        weekStartsOn: 1,
      });
      eventDates = [
        ...eventDates,
        ...generateTasksInTheWeek(
          new Date(startDate),
          currentWeekStart,
          currentWeekEnd,
          daysOfWeekArray,
        ),
      ];
      currentWeekStart = addWeeks(currentWeekStart, intervalCount);
    }
  }
  return eventDates;
};

const generateMonthlyDates = (
  startDate: string,
  end: Date,
  eventDates: Date[],
  intervalCount: number,
) => {
  let currentMonthDate = parseISO(startDate);
  while (currentMonthDate <= end) {
    eventDates.push(new Date(currentMonthDate));
    currentMonthDate = addMonths(currentMonthDate, intervalCount);
  }
  return eventDates;
};

const generateYearlyDates = (
  startDate: string,
  end: Date,
  eventDates: Date[],
  intervalCount: number,
) => {
  let currentYearDate = parseISO(startDate);
  while (currentYearDate <= end) {
    eventDates.push(new Date(currentYearDate));
    currentYearDate = addYears(currentYearDate, intervalCount);
  }
  return eventDates;
};

const generateTasksInTheWeek = (
  originalDateEventWasScheduled: Date,
  startDate: Date,
  endDate: Date,
  daysOfWeekArray: number[],
): Date[] => {
  const dates: Date[] = [];
  let currentDate = endOfDay(startDate);
  while (currentDate <= endDate) {
    const dayOfWeek = getDay(currentDate);
    if (
      daysOfWeekArray.includes(dayOfWeek) &&
      isAfter(currentDate, originalDateEventWasScheduled)
    ) {
      const { hours, minutes, seconds } = extractTimeFromDate(
        originalDateEventWasScheduled,
      );
      currentDate.setHours(hours);
      currentDate.setMinutes(minutes);
      currentDate.setSeconds(seconds);
      dates.push(new Date(currentDate));
    }
    currentDate = addDays(currentDate, 1);
  }
  return dates;
};

const extractTimeFromDate = (date: Date) => {
  const hours = getHours(date);
  const minutes = getMinutes(date);
  const seconds = getSeconds(date);
  return {
    hours,
    minutes,
    seconds,
  };
};
