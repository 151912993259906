import { Tasks } from '../../../../models/Tasks';
import { Habits } from '../../../../models/habits';
import { SubTasks } from '../../../../models/subTasks';
import UserCalendar from '../../../components/UserCalendar/UserCalendar';

interface MyScheduleProps {
  habits: Habits;
  tasks: Tasks;
  subTasks: SubTasks;
}

const MySchedule = ({ habits, tasks, subTasks }: MyScheduleProps) => {
  return (
    <>
      <UserCalendar
        habits={habits}
        tasks={tasks}
        subTasks={subTasks}
        dayViewOnly
        scrollToTime={new Date()}
      />
    </>
  );
};

export default MySchedule;
