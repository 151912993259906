import { z } from 'zod';

export const TaskSchema = z.object({
  id: z.string(),
  userId: z.string(),
  projectId: z.string(),
  title: z.string(),
  description: z.string(),
  completed: z.boolean(),
  completedAt: z.string().nullable(),
  estimatedTime: z.number(),
  startDate: z.string().nullable(),
  priority: z.number(),
});

export type Task = z.infer<typeof TaskSchema>;

export const TasksSchema = z.array(TaskSchema);

export type Tasks = z.infer<typeof TasksSchema>;
