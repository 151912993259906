import { isToday } from 'date-fns';
import { Tasks } from '../models/Tasks';
import { SubTasks } from '../models/subTasks';

const isScheduledToday = (id: string, tasks: Tasks, subTasks: SubTasks) => {
  const task = tasks.find((task) => task.id === id);
  if (task && task.startDate) {
    return isToday(task.startDate);
  }

  const subTask = subTasks.find((subTask) => subTask.id === id);
  if (subTask && subTask.startDate) {
    return isToday(subTask.startDate);
  }

  return false;
};

export default isScheduledToday;
