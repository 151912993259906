import { createSlice } from '@reduxjs/toolkit';
import { InitialHabitCompletionsState, RootState, Status } from '../RootState';
import { completeUserHabit } from '../../api/habits';
import { getUserHabitCompletions } from '../../api/habitCompletions';

const initialState: InitialHabitCompletionsState = {
  habitCompletions: [],
  status: Status.IDLE,
  error: null,
};

export const habitCompletionsSlice = createSlice({
  name: 'habitCompletions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUserHabitCompletions.fulfilled, (state, action) => {
      if (action.payload) {
        state.habitCompletions = action.payload;
        state.status = Status.SUCCEEDED;
      }
    });
    builder.addCase(completeUserHabit.fulfilled, (state, action) => {
      if (action.payload) {
        state.habitCompletions.push(action.payload);
        state.status = Status.SUCCEEDED;
      }
    });
  },
});

export const selectHabitCompletions = (state: RootState) =>
  state.habitCompletionsState.habitCompletions;

export default habitCompletionsSlice.reducer;
