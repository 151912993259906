import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { z } from 'zod';
import { ProjectSchema, ProjectsSchema } from '../models/projects';
import { apiErrorHandler } from './apiErrorHandler';
import { determineCorrectServerUrl } from './util';

const GetUserProjectsRequestSchema = z.string();

const AddUserProjectRequestSchema = z.object({
  title: z.string(),
  description: z.string(),
  userId: z.string(),
});

const EditUserProjectRequestSchema = z.object({
  title: z.string(),
  description: z.string(),
  projectId: z.string(),
});

const CompleteUserProjectRequestSchema = z.string();

const DeleteUserProjectRequestSchema = z.string();

const ReorderProjectsRequestSchema = z.object({
  userId: z.string(),
  projects: z.array(ProjectSchema),
});

export type GetUserProjectsRequest = z.infer<
  typeof GetUserProjectsRequestSchema
>;

export type AddUserProjectRequest = z.infer<typeof AddUserProjectRequestSchema>;

export type EditUserProjectRequest = z.infer<
  typeof EditUserProjectRequestSchema
>;

export type CompleteUserProjectRequest = z.infer<
  typeof CompleteUserProjectRequestSchema
>;

export type DeleteUserProjectRequest = z.infer<
  typeof DeleteUserProjectRequestSchema
>;

export type ReorderProjectsRequest = z.infer<
  typeof ReorderProjectsRequestSchema
>;

export const hydrateUserProjects = createAsyncThunk(
  'projects/hydrateUserProjects',
  async (userId: GetUserProjectsRequest) => {
    try {
      const validatedUserId = GetUserProjectsRequestSchema.parse(userId);
      const userProjects = (
        await axios.get(
          `${determineCorrectServerUrl()}/projects/user/${validatedUserId}`,
          { withCredentials: true },
        )
      ).data;

      const validatedUserProjects = ProjectsSchema.parse(userProjects);

      return validatedUserProjects;
    } catch (error) {
      apiErrorHandler(error);
    }
  },
);

export const addUserProject = createAsyncThunk(
  'projects/addUserProject',
  async (project: AddUserProjectRequest) => {
    try {
      const validatedProject = AddUserProjectRequestSchema.parse(project);

      const newProject = (
        await axios.post(
          `${determineCorrectServerUrl()}/projects`,
          validatedProject,
          { withCredentials: true },
        )
      ).data;

      const validatedNewProject = ProjectSchema.parse(newProject);

      return validatedNewProject;
    } catch (error) {
      apiErrorHandler(error);
    }
  },
);

export const editUserProject = createAsyncThunk(
  'projects/editUserProject',
  async (project: EditUserProjectRequest) => {
    try {
      const validatedProject = EditUserProjectRequestSchema.parse(project);

      const editedProject = (
        await axios.patch(
          `${determineCorrectServerUrl()}/projects/${
            validatedProject.projectId
          }`,
          validatedProject,
          { withCredentials: true },
        )
      ).data;

      const validatedEditedProject = ProjectSchema.parse(editedProject);

      return validatedEditedProject;
    } catch (error) {
      apiErrorHandler(error);
    }
  },
);

export const completeUserProject = createAsyncThunk(
  'projects/completeUserProject',
  async (projectId: CompleteUserProjectRequest) => {
    try {
      const validatedProjectId =
        CompleteUserProjectRequestSchema.parse(projectId);

      const completedProject = (
        await axios.put(
          `${determineCorrectServerUrl()}/projects/complete/${validatedProjectId}`,
          {},
          { withCredentials: true },
        )
      ).data;

      const validatedCompletedProject = ProjectSchema.parse(completedProject);

      return validatedCompletedProject;
    } catch (error) {
      apiErrorHandler(error);
    }
  },
);

export const deleteUserProject = createAsyncThunk(
  'projects/deleteUserProject',
  async (projectId: DeleteUserProjectRequest) => {
    try {
      const validatedProjectId =
        DeleteUserProjectRequestSchema.parse(projectId);

      const deletedProject = (
        await axios.delete(
          `${determineCorrectServerUrl()}/projects/${validatedProjectId}`,
          { withCredentials: true },
        )
      ).data;

      const validatedDeletedProject = ProjectSchema.parse(deletedProject);

      return validatedDeletedProject;
    } catch (error) {
      apiErrorHandler(error);
    }
  },
);

export const reorderProjects = createAsyncThunk(
  'projects/reorderProjects',
  async (reorderData: ReorderProjectsRequest) => {
    try {
      const { userId, projects } =
        ReorderProjectsRequestSchema.parse(reorderData);
      const reorderedProjects = (
        await axios.patch(
          `${determineCorrectServerUrl()}/projects/reorder/${userId}`,
          projects,
          { withCredentials: true },
        )
      ).data;

      const validatedReorderedProjects =
        ProjectsSchema.parse(reorderedProjects);

      return validatedReorderedProjects;
    } catch (error) {
      apiErrorHandler(error);
    }
  },
);
