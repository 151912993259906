import styled from 'styled-components';

export const StyledYellowButton = styled.input`
  background-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.primaryText};
  margin-top: 10px;
  width: 80px;
  border-radius: 30px;
  padding: 7px;
  border: none;
  align-self: flex-end;
  cursor: pointer;
`;
