import {
  StyledCompleteLogo,
  StyledHabit,
  StyledHabitDetailText,
  StyledHabitDetailsContainer,
  StyledHabitIconsContainer,
  StyledHabitTitle,
} from './Styles';
import complete from './../../../../assets/icons/complete.png';
import trash from './../../../../assets/icons/trash.png';
import edit from './../../../../assets/icons/edit.png';
import calendar from './../../../../assets/icons/coloredCalendar.png';
import IconButton from '../../../components/IconButton/IconButton';
import { HabitFormContext } from '../../../../contexts/HabitFormContext';
import { useCustomContext } from '../../../../hooks/useCustomContext';
import { useAppSelector } from '../../../../store/store';
import { selectHabitCompletions } from '../../../../features/habitCompletions/habitCompletionsSlice';
import { selectHabits } from '../../../../features/habits/habitsSlice';
import {
  calculateCompletionRate,
  getHabitStreak,
} from './helpers/getHabitInfo';
import {
  formatMinutesToString,
  formatTimeRange,
} from '../../../../helpers/time';

interface HabitProps {
  id: string;
  title: string;
  estimatedTime: number;
  startDate: string;
  setCalendarOpen: (open: boolean) => void;
  habitCompletionCelebration: () => void;
}

const Habit = ({
  id,
  title,
  estimatedTime,
  startDate,
  setCalendarOpen,
  habitCompletionCelebration,
}: HabitProps) => {
  const habits = useAppSelector(selectHabits);
  const thisHabit = habits.find((habit) => habit.id === id);
  const habitCompletions = useAppSelector(selectHabitCompletions);
  const { handleEditButtonClicked, handleCompleteItem, handleDeleteItem } =
    useCustomContext(HabitFormContext);

  return (
    <StyledHabit>
      <StyledHabitTitle>✍️ {title}</StyledHabitTitle>
      <StyledHabitDetailsContainer>
        <StyledHabitDetailText>
          ⌛️ {formatMinutesToString(estimatedTime)}
        </StyledHabitDetailText>
        <StyledHabitDetailText>
          📆 {formatTimeRange(startDate, estimatedTime)}
        </StyledHabitDetailText>
        <StyledHabitDetailText>
          🎉 {calculateCompletionRate(thisHabit, habitCompletions)}% Completion
          Rate
        </StyledHabitDetailText>
        <StyledHabitDetailText>
          🔥 {getHabitStreak(thisHabit, habitCompletions)} days
        </StyledHabitDetailText>
      </StyledHabitDetailsContainer>
      <StyledHabitIconsContainer>
        <StyledCompleteLogo
          onClick={() => {
            handleCompleteItem(id);
            habitCompletionCelebration();
          }}
          src={complete}
          alt="complete"
        />
        <IconButton
          onClick={() => setCalendarOpen(true)}
          src={calendar}
          alt="open calendar"
        />
        <IconButton
          onClick={() => handleEditButtonClicked(id)}
          src={edit}
          alt="edit"
        />
        <IconButton
          onClick={() => handleDeleteItem(id)}
          src={trash}
          alt="delete"
        />
      </StyledHabitIconsContainer>
    </StyledHabit>
  );
};

export default Habit;
