export const priorityOptions = [
  { title: 'Low', value: 1 },
  { title: 'Medium', value: 2 },
  { title: 'High', value: 3 },
  { title: 'ASAP', value: 4 },
];

export const timeEstimationOptions = [
  { title: '5 minutes', value: 5 },
  { title: '15 minutes', value: 15 },
  { title: '30 minutes', value: 30 },
  { title: '45 minutes', value: 45 },
  { title: '1 hour', value: 60 },
  { title: '1 hour, 15 minutes', value: 75 },
  { title: '1 hour, 30 minutes', value: 90 },
  { title: '1 hour, 45 minutes', value: 105 },
  { title: '2 hours', value: 120 },
  { title: '2 hours, 15 minutes', value: 135 },
  { title: '2 hours, 30 minutes', value: 150 },
  { title: '2 hours, 45 minutes', value: 165 },
  { title: '3 hours', value: 180 },
  { title: '3 hours, 15 minutes', value: 195 },
  { title: '3 hours, 30 minutes', value: 210 },
  { title: '3 hours, 45 minutes', value: 225 },
  { title: '4 hours', value: 240 },
  { title: '4 hours, 15 minutes', value: 255 },
  { title: '4 hours, 30 minutes', value: 270 },
  { title: '4 hours, 45 minutes', value: 285 },
  { title: '5 hours', value: 300 },
  { title: '6 hours', value: 360 },
  { title: '7 hours', value: 420 },
  { title: '8 hours', value: 480 },
  { title: '9 hours', value: 540 },
  { title: '10 hours', value: 600 },
  { title: '11 hours', value: 660 },
  { title: '12 hours', value: 720 },
  { title: '13 hours', value: 780 },
  { title: '14 hours', value: 840 },
  { title: '15 hours', value: 900 },
  { title: '16 hours', value: 960 },
];

export const generateTimeOptions = () => {
  const timeOptions = [];
  let valueCounter = 0;

  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 15) {
      const hourFormatted = hour % 12 === 0 ? 12 : hour % 12;
      const amPm = hour < 12 ? 'am' : 'pm';

      const minuteFormatted = minute.toString().padStart(2, '0');

      const title = `${hourFormatted}:${minuteFormatted}${amPm}`;

      timeOptions.push({ title, value: valueCounter });

      valueCounter += 15;
    }
  }
  return timeOptions;
};
