import styled from 'styled-components';

export const StyledHabitTemplatesButton = styled.button`
  background-color: transparent;
  border-color: ${({ theme }) => theme.colors.primary};
  border: solid;
  flex: 0 1 calc(25% - 20px);
  height: 30px;
  min-width: 135px;
  color: ${({ theme }) => theme.colors.primary};
  font-size: ${({ theme }) => theme.colors.buttons.primaryFontSize};
  font-weight: bold;
  border-radius: 50px;
  &:hover {
    cursor: pointer;
    opacity: 0.5;
  }
`;
