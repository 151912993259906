import styled from 'styled-components';

export const ScrollableProjectList = styled.div`
  overflow-y: auto;
  padding: 10px;
  max-height: 500px;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;
