import { createSlice } from '@reduxjs/toolkit';
import { InitialTasksState, RootState, Status } from '../RootState';
import {
  addUserTask,
  completeUserTask,
  deleteUserTask,
  editUserTask,
  hydrateUserTasks,
  incompleteUserTask,
} from '../../api/tasks';
import { Task } from '../../models/Tasks';
import { incompleteUserSubTask } from '../../api/subTasks';

const initialState: InitialTasksState = {
  tasks: [],
  status: Status.IDLE,
  error: null,
};

export const tasksSlice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(hydrateUserTasks.fulfilled, (state, action) => {
      state.status = Status.SUCCEEDED;
      if (action.payload) {
        state.tasks = action.payload;
      }
    });
    builder.addCase(addUserTask.fulfilled, (state, action) => {
      if (action.payload) {
        state.tasks.push(action.payload);
        state.status = Status.SUCCEEDED;
      }
    });
    builder.addCase(editUserTask.fulfilled, (state, action) => {
      if (action.payload) {
        const { id } = action.payload;
        const taskIndex = state.tasks.findIndex((task) => task.id === id);
        if (taskIndex !== -1) {
          state.tasks[taskIndex] = action.payload;
          state.status = Status.SUCCEEDED;
        }
      }
    });
    builder.addCase(completeUserTask.fulfilled, (state, action) => {
      if (action.payload) {
        const { id, completed, completedAt } = action.payload;
        const taskIdToComplete = id;
        state.tasks = state.tasks.map((task: Task) =>
          task.id === taskIdToComplete
            ? {
                ...task,
                completed: completed,
                completedAt: completedAt,
              }
            : task,
        );
        state.status = Status.SUCCEEDED;
      }
    });
    builder.addCase(incompleteUserTask.fulfilled, (state, action) => {
      if (action.payload) {
        const taskIdToComplete = action.payload.id;
        state.tasks = state.tasks.map((task: Task) =>
          task.id === taskIdToComplete ? { ...task, completed: false } : task,
        );
        state.status = Status.SUCCEEDED;
      }
    });
    builder.addCase(incompleteUserSubTask.fulfilled, (state, action) => {
      if (action.payload) {
        const taskIdToIncomplete = action.payload.taskId;
        state.tasks = state.tasks.map((task: Task) => {
          const taskIndex = state.tasks.findIndex(
            (task) => task.id === taskIdToIncomplete,
          );
          if (taskIndex !== -1) {
            state.tasks[taskIndex].completed = false;
          }
          return task;
        });
        state.status = Status.SUCCEEDED;
      }
    });
    builder.addCase(deleteUserTask.fulfilled, (state, action) => {
      if (action.payload) {
        const taskIdToDelete = action.payload.id;
        state.tasks = state.tasks.filter(
          (task: Task) => task.id !== taskIdToDelete,
        );
        state.status = Status.SUCCEEDED;
      }
    });
  },
});

export const selectTasks = (state: RootState) => state.tasksState.tasks;

export default tasksSlice.reducer;
