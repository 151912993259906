import { useEffect, useState } from 'react';
import { selectProjects } from '../../../../features/projects/projectsSlice';
import { useAppSelector } from '../../../../store/store';
import Modal from '../../../components/Modal/Modal';
import {
  generateTimeOptions,
  timeEstimationOptions,
} from '../../../components/TaskModal/dropDownOptions';
import TitledTextBox from '../../../components/TitledTextBox/TitledTextBox';
import HabitRecurrenceSetting from '../HabitRecurrenceSetting/HabitRecurrenceSetting';
import {
  RightAlignedExtraTopMarginYellowButton,
  StyledHabitModalContainer,
  StyledLeftColumn,
  StyledRightColumn,
} from './Styles';
import 'react-calendar/dist/Calendar.css';
import { format } from 'date-fns';
import PopUpCalendar from '../../../components/PopUpCalendar/PopUpCalendar';
import { useForm } from 'react-hook-form';
import { useCustomContext } from '../../../../hooks/useCustomContext';
import { HabitFormContext } from '../../../../contexts/HabitFormContext';
import { HabitModalFormValues } from '../../../../models/formModalContexts';

interface HabitModalProps {
  isOpen: boolean;
  habitDate: string;
}

const HabitModal = ({ isOpen }: HabitModalProps) => {
  const projects = useAppSelector(selectProjects);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const {
    formModalOpen,
    editMode,
    handleFormSubmitButtonClicked,
    fillFormOnEdit,
    onFormModalClose,
  } = useCustomContext(HabitFormContext);

  const { register, handleSubmit, setValue, reset, watch } =
    useForm<HabitModalFormValues>({
      defaultValues: {
        title: '',
        description: '',
        estimatedTime: 5,
        projectId: '',
        startDate: '',
        startTime: '',
        intervalCount: 1,
        intervalType: 'daily',
        daysOfWeek: '',
      },
    });

  useEffect(() => {
    fillFormOnEdit(setValue, reset);
  }, [editMode, isOpen, reset, setValue]);

  const generateProjectOptions = () => {
    return projects.map((project) => {
      return { title: project.title, value: project.id };
    });
  };

  const handleDateChange = (date: Date) => {
    setCalendarOpen(false);
    setValue('startDate', format(date, 'MM-dd-yyyy'));
  };

  const onSubmit = (data: HabitModalFormValues) => {
    handleFormSubmitButtonClicked({
      ...data,
      daysOfWeek: watch('daysOfWeek'),
      intervalCount: Number(watch('intervalCount')),
    });
    reset();
  };

  return (
    <Modal
      isOpen={formModalOpen}
      onClose={() => {
        onFormModalClose();
        reset();
      }}
      large
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledHabitModalContainer>
          <StyledLeftColumn>
            <TitledTextBox
              label={'title'}
              register={register}
              title={'Title'}
              required
            />
            <TitledTextBox
              register={register}
              title={'Description'}
              label={'description'}
              size={'large'}
            />
            <HabitRecurrenceSetting
              register={register}
              watch={watch}
              setValue={setValue}
              defaultRecurrenceSetting={watch('daysOfWeek') || undefined}
            />
          </StyledLeftColumn>
          <StyledRightColumn>
            <TitledTextBox
              title={'Time Estimation'}
              label={'estimatedTime'}
              register={register}
              dropdownOptions={timeEstimationOptions}
              required
            />
            <TitledTextBox
              title={'Project'}
              label={'projectId'}
              register={register}
              dropdownOptions={generateProjectOptions()}
              required
            />
            <TitledTextBox
              title={'Start Date'}
              label={'startDate'}
              register={register}
              onClick={() => setCalendarOpen(true)}
              required
            />
            <PopUpCalendar
              onClickDay={() => setCalendarOpen(false)}
              calendarOpen={calendarOpen}
              onChange={handleDateChange}
            />
            <TitledTextBox
              title={'Start Time'}
              label={'startTime'}
              register={register}
              dropdownOptions={generateTimeOptions()}
              required
            />
            <RightAlignedExtraTopMarginYellowButton editMode={editMode} />
          </StyledRightColumn>
        </StyledHabitModalContainer>
      </form>
    </Modal>
  );
};

export default HabitModal;
