import axios from 'axios';
import { apiErrorHandler } from './apiErrorHandler';
import { determineCorrectServerUrl } from './util';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { HabitSchema, HabitsSchema } from '../models/habits';
import { z } from 'zod';
import { HabitCompletionSchema } from '../models/habitCompletions';

const HabitIdSchema = z.string();

const GetUserHabitsRequestSchema = z.string();

const AddUserHabitRequestSchema = z.object({
  userId: z.string(),
  projectId: z.string(),
  title: z.string(),
  description: z.string(),
  startDate: z.string(),
  intervalType: z.string(),
  intervalCount: z.number().nullable(),
  daysOfWeek: z.string().nullable(),
  estimatedTime: z.number(),
  order: z.number(),
});

const EditUserHabitRequestSchema = z.object({
  id: z.string(),
  userId: z.string(),
  projectId: z.string(),
  title: z.string(),
  description: z.string(),
  startDate: z.string(),
  intervalType: z.string(),
  intervalCount: z.number().nullable(),
  daysOfWeek: z.string().nullable(),
  estimatedTime: z.number(),
  order: z.number(),
});

const CompleteUserHabitRequestSchema = z.object({
  userId: z.string(),
  habitId: z.string(),
  estimatedTime: z.number(),
});

export type GetUserHabitsRequest = z.infer<typeof GetUserHabitsRequestSchema>;

export type AddUserHabitRequest = z.infer<typeof AddUserHabitRequestSchema>;

export type CompleteUserHabitRequest = z.infer<
  typeof CompleteUserHabitRequestSchema
>;

export type EditUserHabitRequest = z.infer<typeof EditUserHabitRequestSchema>;

export const hydrateUserHabits = createAsyncThunk(
  'habits/hydrateUserHabits',
  async (userId: GetUserHabitsRequest) => {
    try {
      const habits = (
        await axios.get(
          `${determineCorrectServerUrl()}/habits/user/${userId}`,
          {
            withCredentials: true,
          },
        )
      ).data;
      const validatedHabits = HabitsSchema.parse(habits);

      return validatedHabits;
    } catch (error) {
      apiErrorHandler(error);
    }
  },
);

export const addUserHabit = createAsyncThunk(
  'habits/addUserHabit',
  async (habit: AddUserHabitRequest) => {
    try {
      const validatedHabit = AddUserHabitRequestSchema.parse(habit);

      const newHabit = (
        await axios.post(
          `${determineCorrectServerUrl()}/habits`,
          validatedHabit,
          { withCredentials: true },
        )
      ).data;

      const validatedNewHabit = HabitSchema.parse(newHabit);

      return validatedNewHabit;
    } catch (error) {
      apiErrorHandler(error);
    }
  },
);

export const completeUserHabit = createAsyncThunk(
  'habits/completeUserHabit',
  async (data: CompleteUserHabitRequest) => {
    try {
      const { userId, habitId, estimatedTime } =
        CompleteUserHabitRequestSchema.parse(data);

      const completedHabit = (
        await axios.post(
          `${determineCorrectServerUrl()}/habits/complete/${habitId}`,
          {
            userId: userId,
            date: new Date(),
            estimatedTime,
          },
          { withCredentials: true },
        )
      ).data;

      const validatedCompletedHabit =
        HabitCompletionSchema.parse(completedHabit);

      return validatedCompletedHabit;
    } catch (error) {
      apiErrorHandler(error);
    }
  },
);

export const editUserHabit = createAsyncThunk(
  'habits/editUserHabit',
  async (habit: EditUserHabitRequest) => {
    try {
      const validatedHabit = EditUserHabitRequestSchema.parse(habit);

      const editedHabit = (
        await axios.patch(
          `${determineCorrectServerUrl()}/habits/${validatedHabit.id}`,
          validatedHabit,
          { withCredentials: true },
        )
      ).data;

      const validatedEditedHabit = HabitSchema.parse(editedHabit);

      return validatedEditedHabit;
    } catch (error) {
      apiErrorHandler(error);
    }
  },
);

export const deleteUserHabit = createAsyncThunk(
  'habits/deleteUserHabit',
  async (habitId: string) => {
    try {
      const validatedHabitId = HabitIdSchema.parse(habitId);

      const deletedHabit = (
        await axios.delete(
          `${determineCorrectServerUrl()}/habits/${validatedHabitId}`,
          { withCredentials: true },
        )
      ).data;

      return deletedHabit;
    } catch (error) {
      apiErrorHandler(error);
    }
  },
);
