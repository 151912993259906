import { z } from 'zod';

export const HabitCompletionSchema = z.object({
  id: z.string(),
  date: z.string(),
  estimatedTime: z.number(),
  habitId: z.string(),
});

export type HabitCompletion = z.infer<typeof HabitCompletionSchema>;

export const HabitCompletionsSchema = z.array(HabitCompletionSchema);

export type HabitCompletions = z.infer<typeof HabitCompletionsSchema>;
