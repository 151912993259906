import styled from 'styled-components';
import { device } from '../../themes/breakpoints';

export const DraggableProjectContainer = styled.div<{ grabbed: string }>`
  height: 100%;
  cursor: ${(props) => props.grabbed};
  margin-right: 15px;

  @media ${device.sm} {
    margin-right: 0px;
  }
`;
