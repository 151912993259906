import { ThemeProvider } from 'styled-components';

const theme = {
  colors: {
    primary: '#1E1E1E',
    secondaryBackground: '#f5f5f5',
    primaryText: '#FFFFFF',
    primaryTaskBackground: '#FFFFFF',
    primaryModalBackground: '#FFFFFF',
    primaryTextBoxBackground: '#EEEEEE',
    primaryTextBoxBorder: '#C1C1C1',
    containers: {
      primaryBorderColor: '#EEEEEE',
      primaryTextColor: '#838383',
      primaryFontSize: '12px',
    },
    buttons: {
      primaryFontColor: '#FFFFFF',
      primaryFontSize: '12px',
    },
  },
};

interface GeneralThemeProviderProps {
  children: React.ReactNode;
}

const GeneralThemeProvider = ({ children }: GeneralThemeProviderProps) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default GeneralThemeProvider;
