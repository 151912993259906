import { StyledWeeklyDayOption } from './Styles';
import { useTheme } from 'styled-components';

interface WeeklyDayOptionProps {
  dayShortHand: string;
  day: string;
  onClick: (day: string) => void;
  isSelected?: boolean;
}

const WeeklyDayOption = ({
  dayShortHand,
  day,
  onClick,
  isSelected,
}: WeeklyDayOptionProps) => {
  const theme = useTheme();
  return (
    <StyledWeeklyDayOption
      onClick={() => {
        onClick(day);
      }}
      bg={
        isSelected
          ? theme.colors.primary
          : theme.colors.primaryTextBoxBackground
      }
    >
      {dayShortHand}
    </StyledWeeklyDayOption>
  );
};

export default WeeklyDayOption;
