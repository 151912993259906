import { createContext, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../store/store';
import {
  addUserProject,
  completeUserProject,
  deleteUserProject,
  editUserProject,
} from '../api/projects';
import { selectUser } from '../features/user/userSlice';
import {
  IFormContext,
  ProjectModalFormValues,
} from '../models/formModalContexts';
import { selectProjects } from '../features/projects/projectsSlice';
import { UseFormReset, UseFormSetValue } from 'react-hook-form';

const defaultProjectFormContextValues = {
  formModalOpen: false,
  setFormModalOpen: () => {},
  selectedItemId: '',
  setSelectedItemId: () => {},
  editMode: false,
  setEditMode: () => {},
  fillFormOnEdit: () => {},
  handleFormSubmitButtonClicked: () => {},
  handleEditButtonClicked: () => {},
  handleCompleteItem: () => {},
  handleDeleteItem: () => {},
  onFormModalOpen: () => {},
  onFormModalClose: () => {},
};

export const ProjectFormContext = createContext<
  IFormContext<ProjectModalFormValues>
>(defaultProjectFormContextValues);

interface ProjectFormContextProviderProps {
  children: React.ReactNode;
}

export const ProjectFormContextProvider = ({
  children,
}: ProjectFormContextProviderProps) => {
  const dispatch = useAppDispatch();
  const projects = useAppSelector(selectProjects);
  const { id } = useAppSelector(selectUser);
  const [projectFormModalOpen, setProjectFormModalOpen] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState('');
  const [editMode, setEditMode] = useState(false);

  const clearContextValues = () => {
    setProjectFormModalOpen(false);
    setSelectedProjectId('');
    setEditMode(false);
  };

  const fillFormOnEdit = (
    setValue: UseFormSetValue<ProjectModalFormValues>,
    reset: UseFormReset<ProjectModalFormValues>,
  ) => {
    if (editMode) {
      const project = projects.find(
        (project) => project.id === selectedProjectId,
      );
      if (project) {
        setValue('title', project.title);
        setValue('description', project.description);
      }
    } else {
      reset();
    }
  };

  const handleFormSubmitButtonClicked = (data: ProjectModalFormValues) => {
    if (editMode) {
      dispatch(editUserProject({ ...data, projectId: selectedProjectId }));
    } else {
      dispatch(addUserProject({ ...data, userId: id }));
    }
    clearContextValues();
  };

  const handleEditButtonClicked = (projectId: string) => {
    onFormModalOpen();
    setSelectedProjectId(projectId);
    setEditMode(true);
  };

  const handleCompleteItem = (projectId: string) => {
    dispatch(completeUserProject(projectId));
  };

  const handleDeleteItem = async (projectId: string) => {
    dispatch(deleteUserProject(projectId));
  };

  const onFormModalOpen = () => {
    setProjectFormModalOpen(true);
  };

  const onFormModalClose = () => {
    clearContextValues();
  };

  return (
    <ProjectFormContext.Provider
      value={{
        formModalOpen: projectFormModalOpen,
        setFormModalOpen: setProjectFormModalOpen,
        selectedItemId: selectedProjectId,
        setSelectedItemId: setSelectedProjectId,
        editMode,
        setEditMode,
        fillFormOnEdit,
        handleFormSubmitButtonClicked,
        handleEditButtonClicked,
        handleCompleteItem,
        handleDeleteItem,
        onFormModalOpen,
        onFormModalClose,
      }}
    >
      {children}
    </ProjectFormContext.Provider>
  );
};
