import { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/store';
import InfoContainerHeader from '../components/InfoContainerHeader/InfoContainerHeader';
import PageContainer from '../components/PageContainer/PageContainer';
import { selectUser } from '../../features/user/userSlice';
import { hydrateUserTasks } from '../../api/tasks';
import { hydrateUserSubTasks } from '../../api/subTasks';
import { selectTasks } from '../../features/tasks/tasksSlice';
import { selectSubTasks } from '../../features/subTasks/subTasksSlice';
import FilteredList from '../components/FilteredList/FilteredList';
import {
  DashboardContainer,
  DashboardRow,
  DashboardStatsRow,
  MiddleDashboardRow,
  MiddleDashboardRowInfoContainer,
  ScrollableContainer,
  TopDashboardRowStatsInfo,
} from './Styles';
import {
  getAverageEstimatedTimeWorkedPerDay,
  getAverageNumberOfTasksCompletedPerDay,
  getNumberOfTasksCompleted,
  getTotalEstimatedTimeWorked,
} from './helpers/StatCalculations';
import StatsGraph from './components/StatsGraph/StatsGraph';
import { hydrateUserHabits } from '../../api/habits';
import { selectHabits } from '../../features/habits/habitsSlice';
import { selectHabitCompletions } from '../../features/habitCompletions/habitCompletionsSlice';
import MySchedule from './components/MySchedule/MySchedule';
import { getUserHabitCompletions } from '../../api/habitCompletions';
import TaskModal from '../components/TaskModal/TaskModal';
import { useCustomContext } from '../../hooks/useCustomContext';
import { GenericTaskFormContext } from '../../contexts/GenericTaskFormContext';
import { hydrateUserProjects } from '../../api/projects';
import IconButton from '../components/IconButton/IconButton';
import newIcon from './../../assets/icons/newIcon.png';
import TaskOptionModal from './components/TaskOptionModal/TaskOptionModal';
import AddExistingTaskModal from './components/AddExistingTaskModal/AddExistingTaskModal';
import { SelectedTasksContextProvider } from '../../contexts/SelectedTasksContext';

const Dashboard = () => {
  const dispatch = useAppDispatch();
  const { id, createdAt } = useAppSelector(selectUser);
  const tasks = useAppSelector(selectTasks);
  const subTasks = useAppSelector(selectSubTasks);
  const habits = useAppSelector(selectHabits);
  const habitCompletions = useAppSelector(selectHabitCompletions);
  const [taskOptionModalOpen, setTaskOptionModalOpen] = useState(false);
  const [addExistingTaskModalOpen, setAddExistingTaskModalOpen] =
    useState(false);

  const { formModalOpen: taskModalOpen, onFormModalOpen } = useCustomContext(
    GenericTaskFormContext,
  );

  useEffect(() => {
    dispatch(hydrateUserProjects(id));
    dispatch(hydrateUserTasks(id));
    dispatch(hydrateUserSubTasks(id));
    dispatch(hydrateUserHabits(id));
    dispatch(getUserHabitCompletions(id));
  }, [dispatch, id]);

  const tasksCompleted = useMemo(
    () => getNumberOfTasksCompleted(tasks, subTasks, habitCompletions),
    [tasks, subTasks, habitCompletions],
  );

  const avgTasksCompletedPerDay = useMemo(
    () =>
      getAverageNumberOfTasksCompletedPerDay(
        tasks,
        subTasks,
        habitCompletions,
        createdAt,
      ),
    [tasks, subTasks, habitCompletions, createdAt],
  );

  const totalEstimatedTimeWorked = useMemo(
    () => getTotalEstimatedTimeWorked(tasks, subTasks, habitCompletions),
    [tasks, subTasks, habitCompletions],
  );

  const averageEstimatedTimeWorkedPerDay = useMemo(
    () =>
      getAverageEstimatedTimeWorkedPerDay(
        tasks,
        subTasks,
        habitCompletions,
        createdAt,
      ),
    [tasks, subTasks, habitCompletions, createdAt],
  );

  return (
    <PageContainer>
      <DashboardContainer>
        <DashboardStatsRow>
          <TopDashboardRowStatsInfo
            stat={tasksCompleted}
            title={'Tasks Completed'}
          />
          <TopDashboardRowStatsInfo
            stat={avgTasksCompletedPerDay}
            title={'Avg. Tasks Completed / Day'}
          />
          <TopDashboardRowStatsInfo
            stat={totalEstimatedTimeWorked}
            title={'Total Estimated Hours Worked'}
          />
          <TopDashboardRowStatsInfo
            stat={averageEstimatedTimeWorkedPerDay}
            title={'Avg. Estimated Hours Worked / Day'}
          />
        </DashboardStatsRow>
        <MiddleDashboardRow>
          <MiddleDashboardRowInfoContainer>
            <InfoContainerHeader title="My Schedule for Today" />
            <MySchedule habits={habits} tasks={tasks} subTasks={subTasks} />
          </MiddleDashboardRowInfoContainer>
          <MiddleDashboardRowInfoContainer>
            <InfoContainerHeader title="Today's To Do List">
              <IconButton
                onClick={() => setTaskOptionModalOpen(true)}
                src={newIcon}
                alt="add task"
              />
            </InfoContainerHeader>
            <ScrollableContainer>
              <FilteredList
                tasks={tasks}
                subTasks={subTasks}
                habits={habits}
                habitCompletions={habitCompletions}
                showCompleted={false}
                dueToday={true}
              />
            </ScrollableContainer>
          </MiddleDashboardRowInfoContainer>
          <MiddleDashboardRowInfoContainer>
            <InfoContainerHeader title="Tasks Completed Today"></InfoContainerHeader>
            <ScrollableContainer>
              <FilteredList
                tasks={tasks}
                subTasks={subTasks}
                habits={habits}
                habitCompletions={habitCompletions}
                showCompleted
              />
            </ScrollableContainer>
          </MiddleDashboardRowInfoContainer>
        </MiddleDashboardRow>
        <DashboardRow>
          <StatsGraph
            habitCompletions={habitCompletions}
            tasks={tasks}
            subTasks={subTasks}
          />
        </DashboardRow>
      </DashboardContainer>
      <TaskOptionModal
        isOpen={taskOptionModalOpen}
        onClose={() => setTaskOptionModalOpen(false)}
        leftButtonTitle={'Create new task'}
        leftButtonClick={() => {
          setTaskOptionModalOpen(false);
          onFormModalOpen();
        }}
        rightButtonTitle={'Add or remove existing tasks'}
        rightButtonClick={() => {
          setTaskOptionModalOpen(false);
          setAddExistingTaskModalOpen(true);
        }}
      />
      <TaskModal isOpen={taskModalOpen} dueToday />
      <SelectedTasksContextProvider>
        <AddExistingTaskModal
          isOpen={addExistingTaskModalOpen}
          setIsOpen={setAddExistingTaskModalOpen}
          onClose={() => setAddExistingTaskModalOpen(false)}
        />
      </SelectedTasksContextProvider>
    </PageContainer>
  );
};

export default Dashboard;
