import {
  GoHomeButton,
  StyledPageNotFoundBackground,
  StyledPageNotFoundColumnLeft,
  StyledPageNotFoundColumnRight,
  StyledPageNotFoundContainer,
  StyledPageNotFoundSubtitleText,
  StyledPageNotFoundTextContainer,
  StyledPageNotFoundTitleText,
} from './Styles';
import background from './../../assets/setup.png';

const PageNotFound = () => {
  return (
    <StyledPageNotFoundContainer>
      <StyledPageNotFoundColumnLeft>
        <StyledPageNotFoundBackground src={background} />
      </StyledPageNotFoundColumnLeft>
      <StyledPageNotFoundColumnRight>
        <StyledPageNotFoundTextContainer>
          <StyledPageNotFoundTitleText>404</StyledPageNotFoundTitleText>
          <StyledPageNotFoundSubtitleText>
            Page Not Found
          </StyledPageNotFoundSubtitleText>
          <GoHomeButton onClick={() => window.location.replace('/')}>
            Go Home
          </GoHomeButton>
        </StyledPageNotFoundTextContainer>
      </StyledPageNotFoundColumnRight>
    </StyledPageNotFoundContainer>
  );
};

export default PageNotFound;
