import styled from 'styled-components';

export const AddButtonContainer = styled.div`
  background-color: rgb(255, 187, 51, 0.5);
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 330px;
  max-height: 30px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
`;

export const AddButtonText = styled.p`
  margin: 0;
  font-size: 14px;
`;
