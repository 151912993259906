import { Tasks } from '../models/Tasks';
import { HabitCompletions } from '../models/habitCompletions';
import { Habits } from '../models/habits';
import { Projects } from '../models/projects';
import { SubTasks } from '../models/subTasks';
import { User } from '../models/user';

export enum Status {
  IDLE = 'idle',
  LOADING = 'loading',
  SUCCEEDED = 'succeeded',
  FAILED = 'failed',
}

export interface InitialProjectsState {
  projects: Projects;
  status: Status;
  error: string | null;
}

export interface InitialHabitsState {
  habits: Habits;
  status: Status;
  error: string | null;
}

export interface InitialHabitCompletionsState {
  habitCompletions: HabitCompletions;
  status: Status;
  error: string | null;
}

export interface InitialTasksState {
  tasks: Tasks;
  status: Status;
  error: string | null;
}

export interface InitialSubTasksState {
  subTasks: SubTasks;
  status: Status;
  error: string | null;
}

export interface InitialUserState {
  user: User;
  status: Status;
  error: string | null;
}

export interface RootState {
  userState: InitialUserState;
  projectsState: InitialProjectsState;
  habitsState: InitialHabitsState;
  habitCompletionsState: InitialHabitCompletionsState;
  tasksState: InitialTasksState;
  subTasksState: InitialSubTasksState;
}
