import styled from 'styled-components';
import YellowButton from '../YellowButton/YellowButton';
import { device } from '../../../themes/breakpoints';

export const StyledTaskModalContainer = styled.div`
  display: flex;
  gap: 20px;

  @media ${device.md} {
    flex-direction: column;
  }
`;

export const StyledLeftColumn = styled.div`
  width: 100%;
  flex: 2;
`;

export const StyledRightColumn = styled.div`
  width: 100%;
  flex: 1;
`;

interface RightAlignedYellowButtonProps {
  marginTop: string;
}

export const RightAlignedYellowButton = styled(
  YellowButton,
)<RightAlignedYellowButtonProps>`
  margin-top: ${(props) => props.marginTop};
  float: right;
`;
