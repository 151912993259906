import HabitTemplateButton from '../HabitTemplateButton/HabitTemplateButton';
import {
  HabitTemplateButtonsContainer,
  StyledHabitTemplatesContainer,
  StyledHabitTemplatesContainerTitle,
} from './Styles';
import { habitData } from './habitTemplatesData';

const HabitTemplatesContainer = () => {
  return (
    <StyledHabitTemplatesContainer>
      <StyledHabitTemplatesContainerTitle>
        Habit Templates
      </StyledHabitTemplatesContainerTitle>
      <HabitTemplateButtonsContainer>
        {habitData.map((habit, index) => (
          <HabitTemplateButton
            key={index}
            title={habit.title}
            estimatedTime={habit.estimatedTime}
            daysOfWeek={habit.daysOfWeek}
            intervalCount={habit.intervalCount}
            intervalType={habit.intervalType}
          ></HabitTemplateButton>
        ))}
      </HabitTemplateButtonsContainer>
    </StyledHabitTemplatesContainer>
  );
};

export default HabitTemplatesContainer;
