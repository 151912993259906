import { Backdrop, ModalBox } from './Styles';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  large?: boolean;
}

const Modal = ({ isOpen, onClose, children, large }: ModalProps) => {
  if (!isOpen) return null;

  return (
    <Backdrop onClick={onClose}>
      <ModalBox
        onClick={(e) => e.stopPropagation()}
        width={large === true ? '35%' : '25%'}
      >
        {children}
      </ModalBox>
    </Backdrop>
  );
};

export default Modal;
