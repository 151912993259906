import useOutsideClick from '../../../hooks/useOutsideClick';
import { StyledCalendar } from './Styles';
import { useRef } from 'react';

interface PopUpCalendarProps {
  calendarOpen: boolean;
  onClickDay: () => void;
  onChange: (date: Date) => void;
  showOnRight?: boolean;
}

const PopUpCalendar = ({
  calendarOpen,
  onClickDay,
  onChange,
  showOnRight = false,
}: PopUpCalendarProps) => {
  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef, () => onClickDay());
  return (
    <div ref={wrapperRef}>
      <StyledCalendar
        onClickDay={() => onClickDay()}
        visibility={calendarOpen}
        onChange={(date) => {
          if (date instanceof Date) {
            onChange(date);
          }
        }}
        showOnRight={showOnRight}
      />
    </div>
  );
};

export default PopUpCalendar;
