import styled from 'styled-components';

export const StyledInfoContainerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 10px;
`;

export const StyledInfoContainerTitle = styled.p`
  font-weight: bold;
  margin: 0;
`;
