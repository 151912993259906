import { createSlice } from '@reduxjs/toolkit';
import { InitialSubTasksState, RootState, Status } from '../RootState';
import { SubTask } from '../../models/subTasks';
import {
  addUserSubTask,
  completeUserSubTask,
  deleteUserSubTask,
  editUserSubTask,
  hydrateUserSubTasks,
  incompleteUserSubTask,
} from '../../api/subTasks';
import { completeUserTask } from '../../api/tasks';

const initialState: InitialSubTasksState = {
  subTasks: [],
  status: Status.IDLE,
  error: null,
};

export const subTasksSlice = createSlice({
  name: 'subTasks',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(hydrateUserSubTasks.fulfilled, (state, action) => {
      state.status = Status.SUCCEEDED;
      if (action.payload) {
        state.subTasks = action.payload;
      }
    });
    builder.addCase(addUserSubTask.fulfilled, (state, action) => {
      if (action.payload) {
        state.subTasks.push(action.payload);
        state.status = Status.SUCCEEDED;
      }
    });
    builder.addCase(editUserSubTask.fulfilled, (state, action) => {
      if (action.payload) {
        const { id } = action.payload;
        const subTaskIndex = state.subTasks.findIndex(
          (subTask) => subTask.id === id,
        );
        if (subTaskIndex !== -1) {
          state.subTasks[subTaskIndex] = action.payload;
          state.status = Status.SUCCEEDED;
        }
      }
    });
    builder.addCase(completeUserSubTask.fulfilled, (state, action) => {
      if (action.payload) {
        const { id, completed, completedAt } = action.payload;
        const subTaskIdToComplete = id;
        state.subTasks = state.subTasks.map((subTask: SubTask) =>
          subTask.id === subTaskIdToComplete
            ? { ...subTask, completed: completed, completedAt: completedAt }
            : subTask,
        );
        state.status = Status.SUCCEEDED;
      }
    });
    builder.addCase(completeUserTask.fulfilled, (state, action) => {
      if (action.payload) {
        const { id: parentTaskId, completed, completedAt } = action.payload;
        state.subTasks = state.subTasks.map((subTask: SubTask) =>
          subTask.taskId === parentTaskId && !subTask.completed
            ? { ...subTask, completed: completed, completedAt: completedAt }
            : subTask,
        );
        state.status = Status.SUCCEEDED;
      }
    });
    builder.addCase(incompleteUserSubTask.fulfilled, (state, action) => {
      if (action.payload) {
        const subTaskIdToComplete = action.payload.id;
        state.subTasks = state.subTasks.map((subTask: SubTask) =>
          subTask.id === subTaskIdToComplete
            ? { ...subTask, completed: false }
            : subTask,
        );
        state.status = Status.SUCCEEDED;
      }
    });
    builder.addCase(deleteUserSubTask.fulfilled, (state, action) => {
      if (action.payload) {
        const taskIdToDelete = action.payload.id;
        state.subTasks = state.subTasks.filter(
          (subTask: SubTask) => subTask.id !== taskIdToDelete,
        );
        state.status = Status.SUCCEEDED;
      }
    });
  },
});

export const selectSubTasks = (state: RootState) =>
  state.subTasksState.subTasks;

export default subTasksSlice.reducer;
