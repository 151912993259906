import { Context, useContext } from 'react';

export function useCustomContext<T>(customContext: Context<T>) {
  const context = useContext(customContext);
  if (context === undefined) {
    throw new Error(
      'useCustomContext must be used within a provider for your custom context.',
    );
  }
  return context;
}
