import Modal from '../Modal/Modal';
import TitledTextBox from '../TitledTextBox/TitledTextBox';
import {
  RightAlignedYellowButton,
  StyledLeftColumn,
  StyledRightColumn,
  StyledTaskModalContainer,
} from './Styles';
import { useAppSelector } from '../../../store/store';
import { selectProjects } from '../../../features/projects/projectsSlice';
import { useCustomContext } from '../../../hooks/useCustomContext';
import { GenericTaskFormContext } from '../../../contexts/GenericTaskFormContext';
import {
  generateTimeOptions,
  priorityOptions,
  timeEstimationOptions,
} from './dropDownOptions';
import { useEffect, useState } from 'react';
import { format } from 'date-fns';
import PopUpCalendar from '../PopUpCalendar/PopUpCalendar';
import { useForm } from 'react-hook-form';
import { selectUser } from '../../../features/user/userSlice';
import { combineDateWithTime } from '../../../helpers/time';
import { TaskModalFormValues } from '../../../models/formModalContexts';

interface TaskModalProps {
  isOpen: boolean;
  dueToday?: boolean;
}

const TaskModal = ({ isOpen, dueToday }: TaskModalProps) => {
  const { id } = useAppSelector(selectUser);
  const projects = useAppSelector(selectProjects);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<TaskModalFormValues>({
    defaultValues: {
      title: '',
      description: '',
      startDate: dueToday ? format(new Date(), 'MM-dd-yyyy') : null,
      priority: 1,
      estimatedTime: 5,
      projectId: '',
      startTime: '',
    },
  });
  const {
    isSubTask,
    editMode,
    fillFormOnEdit,
    onFormModalClose,
    handleFormSubmitButtonClicked,
    selectedProjectId: selectedProjectIdFromContext,
  } = useCustomContext(GenericTaskFormContext);

  const onSubmit = (data: TaskModalFormValues) => {
    handleFormSubmitButtonClicked({
      ...data,
      estimatedTime: Number(data.estimatedTime),
      priority: Number(data.priority),
      userId: id,
      startDate: data.startDate
        ? combineDateWithTime(data.startDate, Number(data.startTime))
        : null,
    });
  };

  const handleDateChange = (date: Date) => {
    setCalendarOpen(false);
    setValue('startDate', format(date, 'MM-dd-yyyy'));
  };

  useEffect(() => {
    fillFormOnEdit(setValue, reset);
    if (selectedProjectIdFromContext) {
      setValue('projectId', selectedProjectIdFromContext);
    }
  }, [editMode, isSubTask, isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={() => onFormModalClose()} large>
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledTaskModalContainer>
          <StyledLeftColumn>
            <TitledTextBox
              title={'Title'}
              register={register}
              label={'title'}
              required
            />
            <TitledTextBox
              title={'Description'}
              register={register}
              label={'description'}
              size={'large'}
            />
            <TitledTextBox
              title={'Date'}
              register={register}
              label={'startDate'}
              onClick={() => setCalendarOpen(true)}
              placeholder={'MM-DD-YYYY'}
            />
            <PopUpCalendar
              onClickDay={() => setCalendarOpen(false)}
              calendarOpen={calendarOpen}
              onChange={handleDateChange}
            />
          </StyledLeftColumn>
          <StyledRightColumn>
            <TitledTextBox
              title={'Priority'}
              register={register}
              label={'priority'}
              dropdownOptions={priorityOptions}
              required
            />
            <TitledTextBox
              title={'Time Estimation'}
              register={register}
              label={'estimatedTime'}
              dropdownOptions={timeEstimationOptions}
              required
            />
            {!isSubTask && projects && projects.length > 0 && (
              <TitledTextBox
                title={'Project'}
                register={register}
                label={'projectId'}
                dropdownOptions={projects.map((project) => {
                  return { title: project.title, value: project.id };
                })}
                required
              />
            )}
            <TitledTextBox
              title={'Start Time'}
              register={register}
              label={'startTime'}
              dropdownOptions={generateTimeOptions()}
            />
            <RightAlignedYellowButton
              marginTop={isSubTask ? '50px' : '20px'}
              editMode={editMode}
            />
          </StyledRightColumn>
        </StyledTaskModalContainer>
        {errors.title && errors.title.type === 'maxLength' && (
          <p style={{ fontSize: '12px', color: 'red', margin: 0 }}>
            - Maximum title length exceeded
          </p>
        )}
        {errors.description && errors.description.type === 'maxLength' && (
          <p style={{ fontSize: '12px', color: 'red', margin: 0 }}>
            - Maximum description length exceeded
          </p>
        )}
        {errors.startDate && errors.startDate.type === 'pattern' && (
          <p style={{ fontSize: '12px', color: 'red', margin: 0 }}>
            - {errors.startDate.message}
          </p>
        )}
      </form>
    </Modal>
  );
};

export default TaskModal;
