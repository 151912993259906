/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { determineCorrectServerUrl } from './util';
import { CalendarEvent } from '../pages/components/UserCalendar/helpers/calendarEventGeneration';

const convertTimeTrackerDataToCalendarEvents = (timeTrackerData: any) => {
  const calendarEvents: CalendarEvent[] = [];
  timeTrackerData.forEach((timeTracker: any) => {
    timeTracker.sessions.forEach((session: any) => {
      calendarEvents.push({
        id: timeTracker.id,
        title: timeTracker.title + ' - ' + timeTracker.ownerName,
        start: new Date(session.startTime),
        end: new Date(session.endTime),
      });
    });
  });
  return calendarEvents;
};

export const getTimeTrackerData = async (id: string) => {
  const response = (
    await axios.get(`${determineCorrectServerUrl()}/time-tracker/${id}`, {
      withCredentials: true,
    })
  ).data;
  return convertTimeTrackerDataToCalendarEvents(response);
};
