import { createContext, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../store/store';
import {
  AddUserTaskRequest,
  EditUserTaskRequest,
  IncompleteUserTaskRequest,
  addUserTask,
  completeUserTask,
  deleteUserTask,
  editUserTask,
  incompleteUserTask,
} from '../api/tasks';
import {
  AddUserSubTaskRequest,
  addUserSubTask,
  completeUserSubTask,
  deleteUserSubTask,
  editUserSubTask,
  incompleteUserSubTask,
} from '../api/subTasks';
import React from 'react';
import { IFormContext, TaskModalFormValues } from '../models/formModalContexts';
import { format } from 'date-fns';
import { getNumberOfMinutesPassedInDay } from '../helpers/time';
import { selectTasks } from '../features/tasks/tasksSlice';
import { selectSubTasks } from '../features/subTasks/subTasksSlice';
import { UseFormReset, UseFormSetValue } from 'react-hook-form';

interface IGenericTaskFormContext extends IFormContext<TaskModalFormValues> {
  selectedProjectId: string;
  setSelectedProjectId: React.Dispatch<React.SetStateAction<string>>;
  isSubTask: boolean;
  setIsSubTask: React.Dispatch<React.SetStateAction<boolean>>;
  handleIncompleteItem: ({
    taskId,
    parentTaskId,
  }: IncompleteUserTaskRequest) => void;
  handleCreateSubTaskButtonClicked: (parentTaskId: string) => void;
  handleAddTaskButtonClicked: (projectId: string) => void;
}

const defaultContextValue: IGenericTaskFormContext = {
  formModalOpen: false,
  setFormModalOpen: () => {},
  selectedItemId: '',
  setSelectedItemId: () => {},
  selectedProjectId: '',
  setSelectedProjectId: () => {},
  editMode: false,
  setEditMode: () => {},
  isSubTask: false,
  setIsSubTask: () => {},
  fillFormOnEdit: () => {},
  handleFormSubmitButtonClicked: () => {},
  handleEditButtonClicked: () => {},
  handleCreateSubTaskButtonClicked: () => {},
  handleCompleteItem: () => {},
  handleIncompleteItem: () => {},
  handleDeleteItem: () => {},
  onFormModalOpen: () => {},
  onFormModalClose: () => {},
  handleAddTaskButtonClicked: () => {},
};

export const GenericTaskFormContext =
  createContext<IGenericTaskFormContext>(defaultContextValue);

interface GenericTaskFormContextProps {
  children: React.ReactNode;
}

export const GenericTaskFormContextProvider = ({
  children,
}: GenericTaskFormContextProps) => {
  const dispatch = useAppDispatch();
  const tasks = useAppSelector(selectTasks);
  const subTasks = useAppSelector(selectSubTasks);
  const [formModalOpen, setFormModalOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState('');
  const [selectedProjectId, setSelectedProjectId] = useState('');
  const [isSubTask, setIsSubTask] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const clearGenericTaskModalState = () => {
    setSelectedItemId('');
    setSelectedProjectId('');
    setIsSubTask(false);
    setEditMode(false);
    setFormModalOpen(false);
  };

  const isTask = (id: string) => {
    const task = tasks.find((task) => task.id === id);
    if (task) {
      return true;
    } else {
      return false;
    }
  };

  const fillFormOnEdit = (
    setValue: UseFormSetValue<TaskModalFormValues>,
    reset: UseFormReset<TaskModalFormValues>,
  ) => {
    if (editMode && isSubTask) {
      const subTask = subTasks.find((subTask) => subTask.id === selectedItemId);
      if (subTask) {
        setValue('title', subTask.title);
        setValue('description', subTask.description);
        setValue('priority', subTask.priority);
        setValue('estimatedTime', subTask.estimatedTime);
        if (subTask.startDate) {
          setValue('startDate', format(subTask.startDate, 'MM-dd-yyyy'));
          setValue(
            'startTime',
            getNumberOfMinutesPassedInDay(subTask.startDate).toString(),
          );
        }
      }
    } else if (editMode && !isSubTask) {
      const task = tasks.find((task) => task.id === selectedItemId);
      if (task) {
        setValue('title', task.title);
        setValue('description', task.description);
        setValue('priority', task.priority);
        setValue('estimatedTime', task.estimatedTime);
        setValue('projectId', task.projectId);
        if (task.startDate) {
          setValue('startDate', format(task.startDate, 'MM-dd-yyyy'));
          setValue(
            'startTime',
            getNumberOfMinutesPassedInDay(task.startDate).toString(),
          );
        }
      }
    } else {
      reset();
    }
  };

  const createTask = (data: AddUserTaskRequest) => {
    dispatch(addUserTask(data)).then(() => {
      clearGenericTaskModalState();
    });
  };

  const createSubTask = (data: AddUserSubTaskRequest) => {
    dispatch(addUserSubTask({ ...data, taskId: selectedItemId })).then(() => {
      clearGenericTaskModalState();
    });
  };

  const handleCreateSubTaskButtonClicked = (parentTaskId: string) => {
    setIsSubTask(true);
    setFormModalOpen(true);
    setSelectedItemId(parentTaskId);
  };

  const editGenericTask = (data: EditUserTaskRequest) => {
    if (isTask(data.taskId)) {
      dispatch(editUserTask(data)).then(() => {
        clearGenericTaskModalState();
      });
    } else {
      dispatch(editUserSubTask(data)).then(() => {
        clearGenericTaskModalState();
      });
    }
  };

  const handleCompleteItem = async (taskId: string) => {
    if (isTask(taskId)) {
      dispatch(completeUserTask(taskId)).then(() => {
        clearGenericTaskModalState();
      });
    } else {
      dispatch(completeUserSubTask(taskId)).then(() => {
        clearGenericTaskModalState();
      });
    }
  };

  const handleIncompleteItem = async (
    incompleteTaskRequest: IncompleteUserTaskRequest,
  ) => {
    if (isTask(incompleteTaskRequest.taskId)) {
      dispatch(incompleteUserTask(incompleteTaskRequest)).then(() => {
        clearGenericTaskModalState();
      });
    } else {
      dispatch(incompleteUserSubTask(incompleteTaskRequest.taskId)).then(() => {
        clearGenericTaskModalState();
      });
    }
  };

  const handleDeleteItem = async (taskId: string) => {
    if (isTask(taskId)) {
      dispatch(deleteUserTask(taskId)).then(() => {
        clearGenericTaskModalState();
      });
    } else {
      dispatch(deleteUserSubTask(taskId)).then(() => {
        clearGenericTaskModalState();
      });
    }
  };

  const handleFormSubmitButtonClicked = (data: TaskModalFormValues) => {
    if (editMode) {
      editGenericTask({ ...data, taskId: selectedItemId });
    } else {
      if (isSubTask) {
        createSubTask({ ...data, taskId: selectedItemId });
      } else {
        createTask(data);
      }
    }
    clearGenericTaskModalState();
  };

  const handleEditButtonClicked = (id: string) => {
    setEditMode(true);
    setSelectedItemId(id);
    setFormModalOpen(true);
  };

  const onFormModalOpen = () => {
    setFormModalOpen(true);
  };

  const onFormModalClose = () => {
    clearGenericTaskModalState();
  };

  const handleAddTaskButtonClicked = (projectId: string) => {
    setSelectedProjectId(projectId);
    onFormModalOpen();
  };

  return (
    <GenericTaskFormContext.Provider
      value={{
        formModalOpen,
        setFormModalOpen,
        selectedItemId: '',
        setSelectedItemId,
        selectedProjectId,
        setSelectedProjectId,
        editMode,
        setEditMode,
        isSubTask,
        setIsSubTask,
        fillFormOnEdit,
        handleFormSubmitButtonClicked,
        handleEditButtonClicked,
        handleCreateSubTaskButtonClicked,
        handleCompleteItem,
        handleIncompleteItem,
        handleDeleteItem,
        onFormModalOpen,
        onFormModalClose,
        handleAddTaskButtonClicked,
      }}
    >
      {children}
    </GenericTaskFormContext.Provider>
  );
};
