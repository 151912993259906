import {
  StatsContainer,
  StatsNumberText,
  StatsTitleText,
  TextContainer,
} from './Styles';

interface StatsInfoProps {
  stat: number | string;
  title: string;
  className?: string;
}

const StatsInfo = ({ stat, title, className }: StatsInfoProps) => {
  return (
    <StatsContainer className={className}>
      <TextContainer>
        <StatsNumberText>{stat}</StatsNumberText>
        <StatsTitleText>{title}</StatsTitleText>
      </TextContainer>
    </StatsContainer>
  );
};

export default StatsInfo;
