import { HabitFormContext } from '../../../../contexts/HabitFormContext';
import { useCustomContext } from '../../../../hooks/useCustomContext';
import { StyledHabitTemplatesButton } from './Styles';

interface HabitTemplateButtonProps {
  title: string;
  estimatedTime: number;
  daysOfWeek: string | null;
  intervalCount: number;
  intervalType: string;
}

const HabitTemplateButton = ({
  title,
  estimatedTime,
  daysOfWeek,
  intervalCount,
  intervalType,
}: HabitTemplateButtonProps) => {
  const { handlePopulateTemplateHabitData } =
    useCustomContext(HabitFormContext);
  return (
    <StyledHabitTemplatesButton
      onClick={() =>
        handlePopulateTemplateHabitData({
          title,
          estimatedTime,
          daysOfWeek,
          intervalCount,
          intervalType,
        })
      }
    >
      {title}
    </StyledHabitTemplatesButton>
  );
};

export default HabitTemplateButton;
