import { z } from 'zod';

export const SubTaskSchema = z.object({
  id: z.string(),
  userId: z.string(),
  taskId: z.string(),
  title: z.string(),
  description: z.string(),
  completed: z.boolean(),
  completedAt: z.string().nullable(),
  estimatedTime: z.number(),
  startDate: z.string().nullable(),
  priority: z.number(),
});

export type SubTask = z.infer<typeof SubTaskSchema>;

export const SubTasksSchema = z.array(SubTaskSchema);

export type SubTasks = z.infer<typeof SubTasksSchema>;
