import { dayNameToNumber } from '../../../../../helpers/time';
import { HabitCompletions } from '../../../../../models/habitCompletions';
import { Habit } from '../../../../../models/habits';
import {
  add,
  addDays,
  addMonths,
  addYears,
  differenceInCalendarDays,
  differenceInCalendarMonths,
  differenceInCalendarYears,
  format,
  isSameDay,
  parseISO,
  subDays,
  subMonths,
  subYears,
} from 'date-fns';

export const getHabitStreak = (
  habit: Habit | undefined,
  completions: HabitCompletions,
): number => {
  if (!habit) {
    return 0;
  }

  const today = new Date();
  let streak = 0;
  let mostRecentCompletionDate = getMostRecentScheduledDate(
    today,
    habit.startDate,
    habit.intervalType,
    habit.intervalCount,
  );

  while (mostRecentCompletionDate >= parseISO(habit.startDate)) {
    const hasCompletion = completions.some(
      (completion) =>
        isSameDay(mostRecentCompletionDate, parseISO(completion.date)) &&
        completion.habitId === habit.id,
    );

    if (hasCompletion) {
      streak++;
      mostRecentCompletionDate = subtractInterval(
        mostRecentCompletionDate,
        habit.intervalType,
        habit.intervalCount,
        habit.daysOfWeek,
      );
    } else {
      break;
    }
  }

  return streak;
};

const getMostRecentScheduledDate = (
  currentDate: Date,
  startDate: string,
  intervalType: string,
  intervalCount: number,
): Date => {
  let scheduledDate = new Date(startDate);
  while (
    addInterval(scheduledDate, intervalType, intervalCount) <= currentDate
  ) {
    scheduledDate = addInterval(scheduledDate, intervalType, intervalCount);
  }
  return scheduledDate;
};

const addInterval = (
  date: Date,
  intervalType: string,
  intervalCount: number,
): Date => {
  switch (intervalType) {
    case 'daily':
      return addDays(date, intervalCount);
    case 'weekly':
      return addDays(date, intervalCount * 7);
    case 'monthly':
      return addMonths(date, intervalCount);
    case 'yearly':
      return addYears(date, intervalCount);
    default:
      return date;
  }
};

const subtractInterval = (
  date: Date,
  intervalType: string,
  intervalCount: number,
  daysOfWeek: string | null,
): Date => {
  switch (intervalType) {
    case 'daily': {
      return subDays(date, intervalCount);
    }
    case 'weekly': {
      if (daysOfWeek) {
        return getPreviousScheduledCompletionDateInWeeklyInterval(
          daysOfWeek,
          date,
        );
      } else {
        return subDays(date, intervalCount * 7);
      }
    }
    case 'monthly': {
      return subMonths(date, intervalCount);
    }
    case 'yearly': {
      return subYears(date, intervalCount);
    }
    default: {
      return date;
    }
  }
};

const getPreviousScheduledCompletionDateInWeeklyInterval = (
  daysOfWeek: string,
  date: Date,
) => {
  const currentDay = date.getDay();

  const daysOfWeekArray = daysOfWeek
    .split(',')
    .map((day) => dayNameToNumber(day));

  const currentIndex = daysOfWeekArray.indexOf(currentDay);

  let previousIndex = currentIndex - 1;
  if (previousIndex < 0) {
    previousIndex = daysOfWeekArray.length - 1;
  }

  const previousScheduledDay = daysOfWeekArray[previousIndex];

  let differenceInDays = (currentDay - previousScheduledDay + 7) % 7;
  differenceInDays = differenceInDays === 0 ? 7 : differenceInDays;

  return subDays(date, differenceInDays);
};

export const calculateCompletionRate = (
  habit: Habit | undefined,
  completions: HabitCompletions,
): string => {
  if (habit) {
    const startDate = parseISO(habit.startDate);
    const today = new Date();
    const countedDays = new Set<string>(); // To track the dates that have been counted

    const expectedOccurrences = getExpectedOccurrences(habit, today, startDate);

    const actualOccurrences = getActualOccurrences(
      completions,
      habit,
      today,
      startDate,
      countedDays,
    );

    return expectedOccurrences > 0
      ? ((actualOccurrences / expectedOccurrences) * 100).toFixed(1)
      : '0';
  } else {
    return '0';
  }
};

const getExpectedOccurrences = (
  habit: Habit,
  today: Date,
  startDate: Date,
): number => {
  let expectedOccurrences = 0;
  switch (habit.intervalType) {
    case 'daily':
      expectedOccurrences = Math.floor(
        differenceInCalendarDays(today, startDate) / habit.intervalCount + 1,
      );
      break;
    case 'weekly': {
      if (!habit.daysOfWeek) return 1;
      expectedOccurrences = 1;
      let currentDate = startDate;
      while (currentDate < today) {
        currentDate = getNextScheduledCompletionDateInWeeklyInterval(
          habit.daysOfWeek,
          currentDate,
        );
        if (currentDate <= today) {
          expectedOccurrences++;
        }
      }
      break;
    }
    case 'monthly':
      expectedOccurrences = Math.floor(
        differenceInCalendarMonths(today, startDate) / habit.intervalCount + 1,
      );
      break;
    case 'yearly':
      expectedOccurrences = Math.floor(
        differenceInCalendarYears(today, startDate) / habit.intervalCount + 1,
      );
      break;
  }
  return expectedOccurrences;
};

const getActualOccurrences = (
  completions: HabitCompletions,
  habit: Habit,
  today: Date,
  startDate: Date,
  countedDays: Set<string>,
): number => {
  let actualOccurrences = 0;
  completions.forEach((completion) => {
    if (completion.habitId !== habit.id) return;
    const completionDate = parseISO(completion.date);
    if (
      isOnScheduledDay(habit, completionDate, startDate, today) &&
      !countedDays.has(format(completion.date, 'yyyy-MM-dd'))
    ) {
      actualOccurrences++;
      countedDays.add(format(completion.date, 'yyyy-MM-dd'));
    }
  });
  return actualOccurrences;
};

const isOnScheduledDay = (
  habit: Habit,
  date: Date,
  startDate: Date,
  endDate: Date,
): boolean => {
  let nextDate = startDate;
  while (nextDate <= endDate) {
    if (isSameDay(nextDate, date)) {
      return true;
    }

    switch (habit.intervalType) {
      case 'daily':
        nextDate = add(nextDate, { days: habit.intervalCount });
        break;
      case 'weekly':
        if (!habit.daysOfWeek) return false;
        nextDate = getNextScheduledCompletionDateInWeeklyInterval(
          habit.daysOfWeek,
          nextDate,
        );
        break;
      case 'monthly':
        nextDate = add(nextDate, { months: habit.intervalCount });
        break;
      case 'yearly':
        nextDate = add(nextDate, { years: habit.intervalCount });
        break;
    }
  }

  return false;
};

const getNextScheduledCompletionDateInWeeklyInterval = (
  daysOfWeek: string,
  date: Date,
) => {
  const currentDay = date.getDay();

  const daysOfWeekArray = daysOfWeek
    .split(',')
    .map((day) => dayNameToNumber(day));

  const currentIndex = daysOfWeekArray.indexOf(currentDay);

  let nextIndex = currentIndex + 1;
  if (nextIndex >= daysOfWeekArray.length) {
    nextIndex = 0;
  }

  const nextScheduledDay = daysOfWeekArray[nextIndex];

  let differenceInDays = (nextScheduledDay - currentDay + 7) % 7;
  differenceInDays = differenceInDays === 0 ? 7 : differenceInDays;

  return addDays(date, differenceInDays);
};
