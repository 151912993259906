// Styles.ts

import styled from 'styled-components';
import { device } from '../../../themes/breakpoints';
import { FaSignOutAlt } from 'react-icons/fa';

/* Sidebar Container */
export const SidebarContainer = styled.div`
  height: 100vh;
  width: 15vw;
  min-width: 150px;
  position: fixed;
  left: 0;
  top: 0;
  background-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.primaryText};
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  box-shadow: 2px 0 12px rgba(0, 0, 0, 0.1);
  transition:
    width 0.3s ease,
    height 0.3s ease,
    flex-direction 0.3s ease;

  @media ${device.sm} {
    width: 100%;
    height: 60px;
    flex-direction: row;
    align-items: center;
    padding: 0 20px;
  }
`;

/* Logo */
export const Logo = styled.div`
  font-size: 1.8rem;
  font-weight: bold;
  color: #bb86fc;
  cursor: pointer;
  margin-bottom: 40px;
  transition: color 0.3s ease;

  &:hover {
    color: #9b68c8;
  }

  @media ${device.sm} {
    margin-bottom: 0;
    font-size: 1.5rem;
  }
`;

/* Navigation List */
export const NavList = styled.ul`
  list-style-type: none;
  padding: 0;
  width: 100%;

  @media ${device.sm} {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0;
  }
`;

/* Navigation Item */
export const NavItem = styled.li`
  width: 100%;
  padding: 12px 10px;
  font-size: 1rem;
  display: flex;
  align-items: center;
  color: #ffffff;
  cursor: pointer;
  transition:
    background-color 0.3s ease,
    opacity 0.3s ease;
  border-radius: 8px;
  margin-bottom: 10px;

  &:hover {
    background-color: rgba(187, 134, 252, 0.1);
    opacity: 0.9;
  }

  @media ${device.sm} {
    padding: 10px 15px;
    margin: 0 10px;
    font-size: 0.9rem;
    width: auto;
  }
`;

/* Icon Wrapper */
export const IconWrapper = styled.span`
  margin-right: 12px;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  color: #bb86fc;

  @media ${device.sm} {
    margin-right: 8px;
  }
`;

/* Log Out Button */
export const LogOutButton = styled.button`
  font-size: 1rem;
  border: none;
  background-color: transparent;
  color: #ffffff;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition:
    opacity 0.3s ease,
    transform 0.3s ease;
  padding: 10px 0;
  border-radius: 8px;

  &:hover {
    opacity: 0.7;
    transform: translateY(-2px);
  }

  &:active {
    opacity: 0.9;
    transform: translateY(0);
  }

  @media ${device.sm} {
    padding: 8px 15px;
    font-size: 0.9rem;
  }

  /* Icon Styling */
  svg {
    margin-right: 8px;
    font-size: 1rem;
  }
`;

/* Responsive Enhancements */

/* Hide Sidebar Items on Small Screens and Show Hamburger */
export const HamburgerMenu = styled.div`
  display: none;
  flex-direction: column;
  justify-content: space-around;
  width: 25px;
  height: 25px;
  cursor: pointer;

  div {
    width: 100%;
    height: 3px;
    background-color: #ffffff;
    border-radius: 2px;
    transition: all 0.3s ease;
  }

  @media ${device.sm} {
    display: flex;
    margin-left: auto;
  }
`;

/* Mobile Navigation Dropdown */
export const MobileNavDropdown = styled.div<{ isOpen: boolean }>`
  display: none;

  @media ${device.sm} {
    display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: ${(props) => props.theme.colors.primary};
    flex-direction: column;
    padding: 10px 0;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    z-index: 998;
  }
`;

/* Close Icon for Mobile Dropdown */
export const CloseIcon = styled(FaSignOutAlt)`
  display: none;

  @media ${device.sm} {
    display: block;
    position: absolute;
    top: 15px;
    right: 20px;
    font-size: 1.2rem;
    color: #ffffff;
    cursor: pointer;
    transition: color 0.3s ease;

    &:hover {
      color: #bb86fc;
    }
  }
`;
