import axios from 'axios';
import { z } from 'zod';
import { apiErrorHandler } from './apiErrorHandler';
import { SubTaskSchema, SubTasksSchema } from '../models/subTasks';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { determineCorrectServerUrl } from './util';

const GetUserSubTasksRequestSchema = z.string();

const AddUserSubTaskRequestSchema = z.object({
  title: z.string(),
  description: z.string(),
  userId: z.string(),
  taskId: z.string(),
  estimatedTime: z.number(),
  startDate: z.string().nullable(),
  priority: z.number(),
});

const EditUserSubTaskRequestSchema = z.object({
  title: z.string(),
  description: z.string(),
  userId: z.string(),
  taskId: z.string(),
  estimatedTime: z.number(),
  startDate: z.string().nullable(),
  priority: z.number(),
});

const CompleteUserSubTaskRequestSchema = z.string();

const IncompleteUserSubTaskRequestSchema = z.string();

const DeleteUserSubTaskRequestSchema = z.string();

type GetUserSubTasksRequest = z.infer<typeof GetUserSubTasksRequestSchema>;

export type AddUserSubTaskRequest = z.infer<typeof AddUserSubTaskRequestSchema>;

export type EditUserSubTaskRequest = z.infer<
  typeof EditUserSubTaskRequestSchema
>;

type CompleteUserSubTaskRequest = z.infer<
  typeof CompleteUserSubTaskRequestSchema
>;

type IncompleteUserSubTaskRequest = z.infer<
  typeof IncompleteUserSubTaskRequestSchema
>;

type DeleteUserSubTaskRequest = z.infer<typeof DeleteUserSubTaskRequestSchema>;

export const hydrateUserSubTasks = createAsyncThunk(
  'subTasks/hydrateUserSubTasks',
  async (userId: GetUserSubTasksRequest) => {
    try {
      const validatedUserId = GetUserSubTasksRequestSchema.parse(userId);

      const userTasks = (
        await axios.get(
          `${determineCorrectServerUrl()}/subtasks/user/${validatedUserId}`,
          { withCredentials: true },
        )
      ).data;

      const validatedUserTasks = SubTasksSchema.parse(userTasks);
      return validatedUserTasks;
    } catch (error) {
      apiErrorHandler(error);
    }
  },
);

export const addUserSubTask = createAsyncThunk(
  'subTasks/addUserSubTask',
  async (subTask: AddUserSubTaskRequest) => {
    try {
      const validatedSubTask = AddUserSubTaskRequestSchema.parse(subTask);
      const newSubTask = (
        await axios.post(
          `${determineCorrectServerUrl()}/subtasks`,
          validatedSubTask,
          { withCredentials: true },
        )
      ).data;

      const validatedNewSubTask = SubTaskSchema.parse(newSubTask);
      return validatedNewSubTask;
    } catch (error) {
      apiErrorHandler(error);
    }
  },
);

export const editUserSubTask = createAsyncThunk(
  'subTasks/editUserSubTask',
  async (subTask: EditUserSubTaskRequest) => {
    try {
      const validatedSubTask = EditUserSubTaskRequestSchema.parse(subTask);
      const newSubTask = (
        await axios.patch(
          `${determineCorrectServerUrl()}/subtasks/${validatedSubTask.taskId}`,
          validatedSubTask,
          { withCredentials: true },
        )
      ).data;

      const validatedNewSubTask = SubTaskSchema.parse(newSubTask);
      return validatedNewSubTask;
    } catch (error) {
      apiErrorHandler(error);
    }
  },
);

export const completeUserSubTask = createAsyncThunk(
  'subTasks/completeUserTask',
  async (subTaskId: CompleteUserSubTaskRequest) => {
    try {
      const validatedSubTaskId =
        CompleteUserSubTaskRequestSchema.parse(subTaskId);

      const completedSubTask = (
        await axios.patch(
          `${determineCorrectServerUrl()}/subtasks/complete/${validatedSubTaskId}`,
          {},
          { withCredentials: true },
        )
      ).data;

      const validatedCompletedSubTask = SubTaskSchema.parse(completedSubTask);
      return validatedCompletedSubTask;
    } catch (error) {
      apiErrorHandler(error);
    }
  },
);

export const incompleteUserSubTask = createAsyncThunk(
  'subTasks/incompleteUserTask',
  async (subTaskId: IncompleteUserSubTaskRequest) => {
    try {
      const validatedSubTaskId =
        IncompleteUserSubTaskRequestSchema.parse(subTaskId);

      const incompleteSubTask = (
        await axios.patch(
          `${determineCorrectServerUrl()}/subtasks/incomplete/${validatedSubTaskId}`,
          {},
          { withCredentials: true },
        )
      ).data;

      const validatedIncompleteSubTask = SubTaskSchema.parse(incompleteSubTask);
      return validatedIncompleteSubTask;
    } catch (error) {
      apiErrorHandler(error);
    }
  },
);

export const deleteUserSubTask = createAsyncThunk(
  'subTasks/deleteUserSubTask',
  async (subTaskId: DeleteUserSubTaskRequest) => {
    try {
      const validatedSubTaskId =
        DeleteUserSubTaskRequestSchema.parse(subTaskId);

      const deletedSubTask = (
        await axios.delete(
          `${determineCorrectServerUrl()}/subtasks/${validatedSubTaskId}`,
          { withCredentials: true },
        )
      ).data;

      const validatedDeletedSubTask = SubTaskSchema.parse(deletedSubTask);
      return validatedDeletedSubTask;
    } catch (error) {
      apiErrorHandler(error);
    }
  },
);
