import styled from 'styled-components';

export const StyledHabitTemplatesContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 5px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

export const StyledHabitTemplatesContainerTitle = styled.h2`
  align-self: flex-start;
  font-size: 20px;
  margin-left: 20px;
  margin-bottom: 0px;
`;

export const HabitTemplateButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 20px;
  row-gap: 15px;
`;
