import {
  eachDayOfInterval,
  format,
  subDays,
  getMonth,
  parseISO,
} from 'date-fns';
import { Task, Tasks } from '../../../models/Tasks';
import { SubTask, SubTasks } from '../../../models/subTasks';
import { HabitCompletions } from '../../../models/habitCompletions';

const months = [
  'Jan.',
  'Feb.',
  'March',
  'April',
  'May',
  'June',
  'July',
  'Aug.',
  'Sept.',
  'Oct.',
  'Nov.',
  'Dec.',
];

const tasksCompletedInMonth = (
  completedTasks: (Task | SubTask)[],
  monthNumber: number,
) => {
  return completedTasks.filter(
    (task) =>
      task.completed &&
      task.completedAt &&
      getMonth(parseISO(task.completedAt)) === monthNumber,
  ).length;
};

const habitsCompletedInMonth = (
  habitCompletions: HabitCompletions,
  monthNumber: number,
) => {
  return habitCompletions.filter(
    (habitCompletion) =>
      getMonth(parseISO(habitCompletion.date)) === monthNumber,
  ).length;
};

const estimatedTimeWorkedInMonth = (
  completedTasks: (Task | SubTask)[],
  monthNumber: number,
) => {
  return (
    completedTasks
      .filter(
        (task) =>
          task.completed &&
          task.completedAt &&
          task.estimatedTime &&
          getMonth(parseISO(task.completedAt)) === monthNumber,
      )
      .reduce((total, task) => total + task.estimatedTime, 0) / 60
  );
};

const estimatedHabitTimeWorkedInMonth = (
  habitCompletions: HabitCompletions,
  monthNumber: number,
) => {
  return (
    habitCompletions
      .filter(
        (habitCompletion) =>
          getMonth(parseISO(habitCompletion.date)) === monthNumber,
      )
      .reduce(
        (total, habitCompletion) => total + habitCompletion.estimatedTime,
        0,
      ) / 60
  );
};

export const fillWeekGraphData = (
  habitCompletions: HabitCompletions,
  tasks: Task[],
  subTasks: SubTask[],
) => {
  const compiledCompletedTasks = [...tasks, ...subTasks].filter(
    (task): task is Task & { completedAt: string } =>
      task.completed && !!task.completedAt,
  );

  const last7Days = eachDayOfInterval({
    start: subDays(new Date(), 6),
    end: new Date(),
  });

  return last7Days.map((day) => {
    const dayNameWithDate = format(day, 'EEE (MM/dd)');

    const tasksCompletedOnDay =
      compiledCompletedTasks.filter((task) => {
        const taskCompletedDate = format(
          new Date(task.completedAt),
          'yyyy-MM-dd',
        );
        return taskCompletedDate === format(day, 'yyyy-MM-dd');
      }).length + habitCompletions.length;

    const estimatedTimeWorkedOnDay = compiledCompletedTasks.reduce(
      (acc, task) => {
        const taskCompletedDate = format(
          new Date(task.completedAt),
          'yyyy-MM-dd',
        );
        if (taskCompletedDate === format(day, 'yyyy-MM-dd')) {
          return acc + (task.estimatedTime || 0);
        }
        return acc;
      },
      0,
    );

    const estimatedHabitTimeWorkedOnDay = habitCompletions.reduce(
      (acc, habitCompletion) => {
        const habitCompletedDate = format(
          new Date(habitCompletion.date),
          'yyyy-MM-dd',
        );
        if (habitCompletedDate === format(day, 'yyyy-MM-dd')) {
          return acc + habitCompletion.estimatedTime;
        }
        return acc;
      },
      0,
    );

    const totalEstimatedTimeWorkedOnDay =
      estimatedTimeWorkedOnDay + estimatedHabitTimeWorkedOnDay;

    return {
      dates: dayNameWithDate,
      tasksCompleted: tasksCompletedOnDay,
      estimatedTimeWorked: parseFloat(
        (totalEstimatedTimeWorkedOnDay / 60).toFixed(2),
      ),
    };
  });
};

export const fillMonthGraphData = (
  habitCompletions: HabitCompletions,
  tasks: Tasks,
  subTasks: SubTasks,
) => {
  const compiledCompletedTasks = [...tasks, ...subTasks].filter(
    (task) => task.completed,
  );

  return months.map((month, index) => {
    return {
      dates: month,
      tasksCompleted:
        tasksCompletedInMonth(compiledCompletedTasks, index) +
        habitsCompletedInMonth(habitCompletions, index),
      estimatedTimeWorked: (
        estimatedTimeWorkedInMonth(compiledCompletedTasks, index) +
        estimatedHabitTimeWorkedInMonth(habitCompletions, index)
      ).toFixed(2),
    };
  });
};
