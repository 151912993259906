import styled, { keyframes } from 'styled-components';

export const HabitsPageBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const HabitsPageBodyContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  margin-right: 10px;
`;

export const NewHabitButton = styled.button`
  background-color: ${({ theme }) => theme.colors.primary};
  border: none;
  color: ${({ theme }) => theme.colors.buttons.primaryFontColor};
  width: 175px;
  height: 30px;
  border-radius: 50px;
  align-self: flex-start;
  margin-top: 100px;
  font-weight: bold;
  font-size: ${({ theme }) => theme.colors.buttons.primaryFontSize};
  &:hover {
    cursor: pointer;
    opacity: 0.5;
  }
`;

const rainAnimation = keyframes`
  0% { transform: translateY(-100%); }
  100% { transform: translateY(100vh); }
`;

export const EmojiRainContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  pointer-events: none;
  overflow: hidden;
  z-index: 999;
`;

interface EmojiProps {
  duration: number;
  delay: number;
  left: number;
}

export const Emoji = styled.div<EmojiProps>`
  position: absolute;
  font-size: 2rem;
  animation: ${rainAnimation} linear forwards;
  animation-duration: ${({ duration }) => duration}s;
  animation-delay: ${({ delay }) => delay}s;
  left: ${({ left }) => left}%;
`;
