import axios from 'axios';
import { z } from 'zod';
import { TaskSchema, TasksSchema } from '../models/Tasks';
import { apiErrorHandler } from './apiErrorHandler';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { determineCorrectServerUrl } from './util';

const GetUserTasksRequestSchema = z.string();

const AddUserTaskRequestSchema = z.object({
  title: z.string(),
  description: z.string(),
  userId: z.string(),
  projectId: z.string(),
  estimatedTime: z.number(),
  startDate: z.string().nullable(),
  priority: z.number(),
});

const EditUserTaskRequestSchema = z.object({
  title: z.string(),
  description: z.string(),
  userId: z.string(),
  taskId: z.string(),
  estimatedTime: z.number(),
  priority: z.number(),
  startDate: z.string().nullable(),
  projectId: z.string(),
});

const CompleteUserTaskRequestSchema = z.string();

const IncompleteUserTaskId = z.string();

const IncompleteUserTaskRequestSchema = z.object({
  taskId: z.string(),
  parentTaskId: z.string().optional(),
});

const DeleteUserTaskRequestSchema = z.string();

type GetUserTasksRequest = z.infer<typeof GetUserTasksRequestSchema>;

export type AddUserTaskRequest = z.infer<typeof AddUserTaskRequestSchema>;

export type EditUserTaskRequest = z.infer<typeof EditUserTaskRequestSchema>;

type CompleteUserTaskRequest = z.infer<typeof CompleteUserTaskRequestSchema>;

export type IncompleteUserTaskRequest = z.infer<
  typeof IncompleteUserTaskRequestSchema
>;

type DeleteUserTaskRequest = z.infer<typeof DeleteUserTaskRequestSchema>;

export const hydrateUserTasks = createAsyncThunk(
  'tasks/hydrateUserTasks',
  async (userId: GetUserTasksRequest) => {
    try {
      const validatedUserId = GetUserTasksRequestSchema.parse(userId);

      const userTasks = (
        await axios.get(
          `${determineCorrectServerUrl()}/tasks/user/${validatedUserId}`,
          { withCredentials: true },
        )
      ).data;

      const validatedUserTasks = TasksSchema.parse(userTasks);

      return validatedUserTasks;
    } catch (error) {
      apiErrorHandler;
    }
  },
);

export const addUserTask = createAsyncThunk(
  'tasks/addUserTask',
  async (task: AddUserTaskRequest) => {
    try {
      const validatedTask = AddUserTaskRequestSchema.parse(task);

      const newTask = (
        await axios.post(
          `${determineCorrectServerUrl()}/tasks`,
          validatedTask,
          { withCredentials: true },
        )
      ).data;

      const validatedNewTask = TaskSchema.parse(newTask);
      return validatedNewTask;
    } catch (error) {
      apiErrorHandler(error);
    }
  },
);

export const editUserTask = createAsyncThunk(
  'tasks/editUserTask',
  async (task: EditUserTaskRequest) => {
    try {
      const validatedTask = EditUserTaskRequestSchema.parse(task);

      const editedTask = (
        await axios.patch(
          `${determineCorrectServerUrl()}/tasks/${validatedTask.taskId}`,
          validatedTask,
          { withCredentials: true },
        )
      ).data;

      const validatedEditedTask = TaskSchema.parse(editedTask);
      return validatedEditedTask;
    } catch (error) {
      apiErrorHandler(error);
    }
  },
);

export const completeUserTask = createAsyncThunk(
  'tasks/completeUserTask',
  async (taskId: CompleteUserTaskRequest) => {
    try {
      const validatedTaskId = CompleteUserTaskRequestSchema.parse(taskId);

      const completedTask = (
        await axios.patch(
          `${determineCorrectServerUrl()}/tasks/complete/${validatedTaskId}`,
          {},
          { withCredentials: true },
        )
      ).data;

      const validatedCompletedTask = TaskSchema.parse(completedTask);
      return validatedCompletedTask;
    } catch (error) {
      apiErrorHandler(error);
    }
  },
);

export const incompleteUserTask = createAsyncThunk(
  'tasks/incompleteUserTask',
  async (data: IncompleteUserTaskRequest) => {
    try {
      const validatedTaskId = IncompleteUserTaskId.parse(data.taskId);

      const incompleteTask = (
        await axios.patch(
          `${determineCorrectServerUrl()}/tasks/incomplete/${validatedTaskId}`,
          {},
          { withCredentials: true },
        )
      ).data;

      const validatedIncompleteTask = TaskSchema.parse(incompleteTask);
      return validatedIncompleteTask;
    } catch (error) {
      apiErrorHandler(error);
    }
  },
);

export const deleteUserTask = createAsyncThunk(
  'tasks/deleteUserTask',
  async (taskId: DeleteUserTaskRequest) => {
    try {
      const validatedTaskId = DeleteUserTaskRequestSchema.parse(taskId);

      const deletedTask = (
        await axios.delete(
          `${determineCorrectServerUrl()}/tasks/${validatedTaskId}`,
          { withCredentials: true },
        )
      ).data;

      const validatedDeletedTask = TaskSchema.parse(deletedTask);
      return validatedDeletedTask;
    } catch (error) {
      apiErrorHandler(error);
    }
  },
);
