import { SelectedTasksContext } from '../../../../contexts/SelectedTasksContext';
import { selectProjects } from '../../../../features/projects/projectsSlice';
import { selectUser } from '../../../../features/user/userSlice';
import { useCustomContext } from '../../../../hooks/useCustomContext';
import { Project } from '../../../../models/projects';
import { useAppSelector } from '../../../../store/store';
import AddButton from '../../../Projects/components/AddButton/AddButton';
import ProjectContainer from '../../../Projects/components/ProjectContainer/ProjectContainer';
import Modal from '../../../components/Modal/Modal';
import { ScrollableProjectList } from './Styles';

interface AddExistingTaskModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onClose: () => void;
}
const AddExistingTaskModal = ({
  isOpen,
  setIsOpen,
  onClose,
}: AddExistingTaskModalProps) => {
  const { id } = useAppSelector(selectUser);
  const projects = useAppSelector(selectProjects);
  const { submitSelectedTasks } = useCustomContext(SelectedTasksContext);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ScrollableProjectList>
        {projects.length > 0 ? (
          [...projects]
            .sort((a, b) => a.order - b.order)
            .map((project: Project) =>
              !project.completed ? (
                <ProjectContainer
                  key={project.id}
                  userId={id}
                  title={project.title}
                  projectId={project.id}
                  description={project.description}
                  selectionMode={true}
                />
              ) : null,
            )
        ) : (
          <div>You don't have any projects.</div>
        )}
      </ScrollableProjectList>
      <AddButton
        title="Add tasks"
        onClick={() => {
          submitSelectedTasks();
          setIsOpen(false);
        }}
      />
    </Modal>
  );
};

export default AddExistingTaskModal;
