import axios from 'axios';
import { determineCorrectServerUrl } from './util';
import { apiErrorHandler } from './apiErrorHandler';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { z } from 'zod';
import { HabitCompletionsSchema } from '../models/habitCompletions';

const UserIdSchema = z.string();

export const getUserHabitCompletions = createAsyncThunk(
  'habitCompletions/getUserHabitCompletions',
  async (userId: string) => {
    try {
      const validatedUserId = UserIdSchema.parse(userId);

      const habitCompletions = (
        await axios.get(
          `${determineCorrectServerUrl()}/habits/completions/${validatedUserId}`,
          { withCredentials: true },
        )
      ).data;

      const validatedHabitCompletions =
        HabitCompletionsSchema.parse(habitCompletions);

      return validatedHabitCompletions;
    } catch (error) {
      apiErrorHandler(error);
    }
  },
);
