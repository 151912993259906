import Calendar from 'react-calendar';
import styled from 'styled-components';

interface StyledCalendarProps {
  visibility: boolean;
  showOnRight: boolean;
}

export const StyledCalendar = styled(Calendar)<StyledCalendarProps>`
  position: fixed;
  right: ${(props) => (props.showOnRight ? '50px' : 'auto')};
  bottom: 50px;
  visibility: ${(props) => (props.visibility ? 'visible' : 'hidden')};
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  padding: 10px;
  margin-top: 20px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  background-color: white;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  color: #333;
  .react-calendar__navigation {
    margin-bottom: 10px;
  }
  .react-calendar__navigation__label {
    font-size: 20px;
    font-weight: 500;
    color: #333;
  }
  .react-calendar__month-view__days__day--weekend {
    color: #a00;
  }
  .react-calendar__tile--active:enabled {
    background-color: ${(props) => props.theme.colors.primary};
  }
  .react-calendar__tile--now:enabled {
    background-color: #e0e0e0;
  }
`;
