import {
  ScrollableTaskList,
  StyledIconsContainer,
  StyledProjectBodyContainer,
  StyledProjectDescription,
} from './Styles';
import complete from './../../../../assets/icons/complete.png';
import edit from './../../../../assets/icons/edit.png';
import trash from './../../../../assets/icons/trash.png';
import AddButton from '../AddButton/AddButton';
import { useAppDispatch, useAppSelector } from '../../../../store/store';
import { useCallback, useEffect } from 'react';
import { hydrateUserTasks } from '../../../../api/tasks';
import { hydrateUserSubTasks } from '../../../../api/subTasks';
import { selectTasks } from '../../../../features/tasks/tasksSlice';
import { Task } from '../../../../models/Tasks';
import { selectSubTasks } from '../../../../features/subTasks/subTasksSlice';
import InfoContainer from '../../../components/InfoContainer/InfoContainer';
import InfoContainerHeader from '../../../components/InfoContainerHeader/InfoContainerHeader';
import FilteredList from '../../../components/FilteredList/FilteredList';
import IconButton from '../../../components/IconButton/IconButton';
import { useCustomContext } from '../../../../hooks/useCustomContext';
import { ProjectFormContext } from '../../../../contexts/ProjectFormContext';
import { GenericTaskFormContext } from '../../../../contexts/GenericTaskFormContext';

interface ProjectContainerProps {
  userId: string;
  title: string;
  projectId: string;
  description: string;
  selectionMode?: boolean;
}

const ProjectContainer = ({
  userId,
  title,
  projectId,
  description,
  selectionMode = false,
}: ProjectContainerProps) => {
  const dispatch = useAppDispatch();
  const tasks = useAppSelector(selectTasks);
  const subTasks = useAppSelector(selectSubTasks);
  const {
    handleEditButtonClicked,
    handleCompleteItem: handleCompleteProject,
    handleDeleteItem: handleDeleteProject,
  } = useCustomContext(ProjectFormContext);

  const { handleAddTaskButtonClicked } = useCustomContext(
    GenericTaskFormContext,
  );

  const tasksInThisProject = tasks.filter(
    (task: Task) => task.projectId === projectId,
  );

  const getAllTasksFromDB = useCallback(async () => {
    dispatch(hydrateUserTasks(userId));
    dispatch(hydrateUserSubTasks(userId));
  }, [userId, dispatch]);

  useEffect(() => {
    getAllTasksFromDB();
  }, [getAllTasksFromDB]);

  return (
    <InfoContainer>
      <InfoContainerHeader title={title}>
        <StyledIconsContainer>
          {selectionMode ? null : (
            <>
              <IconButton
                onClick={() => handleCompleteProject(projectId)}
                src={complete}
                alt="complete"
              />
              <IconButton
                onClick={() => handleEditButtonClicked(projectId)}
                src={edit}
                alt="edit"
              />
              <IconButton
                onClick={() => handleDeleteProject(projectId)}
                src={trash}
                alt="trash"
                noRightMargin
              />
            </>
          )}
        </StyledIconsContainer>
      </InfoContainerHeader>
      <StyledProjectBodyContainer>
        <StyledProjectDescription>{description}</StyledProjectDescription>
        <ScrollableTaskList>
          <FilteredList
            tasks={tasksInThisProject}
            subTasks={subTasks}
            showCompleted={false}
            selectionMode={selectionMode}
          />
        </ScrollableTaskList>
        {selectionMode ? null : (
          <AddButton onClick={() => handleAddTaskButtonClicked(projectId)} />
        )}
      </StyledProjectBodyContainer>
    </InfoContainer>
  );
};

export default ProjectContainer;
