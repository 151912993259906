import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as Sentry from '@sentry/react';
import { extraErrorDataIntegration } from '@sentry/integrations';

Sentry.init({
  dsn: 'https://a7de82ba2c24ad6da7b6d9670177e126@o4506995646595072.ingest.us.sentry.io/4506995658981376',
  integrations: [extraErrorDataIntegration()],
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
