// Styles.ts

import styled from 'styled-components';
import { device } from '../../themes/breakpoints';

// Base font size
const baseFontSize = '14px';

export const StyledLandingContainer = styled.div`
  width: 100%;
  overflow-x: hidden;
  font-family: 'Roboto', sans-serif;
  color: #ffffff;
  background-color: #1e1e1e; /* Main background color */
  font-size: ${baseFontSize}; /* Set base font size */
`;

/* Navbar Styles */
export const Navbar = styled.nav`
  width: 100%;
  padding: 15px 30px; /* Reduced padding */
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: rgba(30, 30, 30, 0.95);
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.8);
`;

export const NavLogo = styled.a`
  color: #bb86fc;
  font-size: 1.5rem; /* Reduced font size */
  font-weight: bold;
  text-decoration: none;

  &:hover {
    color: #9b68c8;
  }
`;

export const NavLinks = styled.div`
  display: flex;
  align-items: center;
`;

export const NavLinkItem = styled.a`
  color: #ffffff;
  margin-left: 20px; /* Reduced margin */
  font-size: 0.875rem; /* Reduced font size */
  text-decoration: none;
  transition: color 0.3s ease;

  &:hover {
    color: #bb86fc;
  }

  @media (${device.sm}) {
    margin-left: 10px;
    font-size: 0.75rem;
  }
`;

/* Hero Section Styles */
export const HeroSection = styled.section`
  height: 100vh;
  background-image: linear-gradient(
      rgba(30, 30, 30, 0.7),
      rgba(30, 30, 30, 0.7)
    ),
    url('https://picsum.photos/id/2/1920/1080');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  padding: 0 15px; /* Reduced padding */
`;

export const HeroContent = styled.div`
  max-width: 600px;
  margin-left: 5%; /* Adjusted margin */
`;

export const Headline = styled.h1`
  color: #ffffff;
  font-size: 2.5rem; /* Reduced font size */
  margin-bottom: 15px;
  font-weight: 700;
  line-height: 1.2;

  @media (${device.md}) {
    font-size: 2rem;
  }

  @media (${device.sm}) {
    font-size: 1.75rem;
  }
`;

export const SubHeadline = styled.p`
  color: #cccccc;
  font-size: 1.25rem; /* Reduced font size */
  margin-bottom: 30px;
  line-height: 1.5;

  @media (${device.md}) {
    font-size: 1.1rem;
    margin-bottom: 25px;
  }

  @media (${device.sm}) {
    font-size: 0.875rem;
    margin-bottom: 15px;
  }
`;

export const CTAButton = styled.button`
  padding: 12px 30px; /* Reduced padding */
  font-size: 0.875rem; /* Reduced font size */
  color: #ffffff;
  background-color: #bb86fc;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition:
    background-color 0.3s ease,
    box-shadow 0.3s ease;
  box-shadow: 0 4px 12px rgba(187, 134, 252, 0.4);

  &:hover {
    background-color: #9b68c8;
    box-shadow: 0 6px 18px rgba(155, 104, 200, 0.5);
  }
`;

/* Features Section Styles */
export const FeaturesSection = styled.section`
  padding: 80px 15px; /* Reduced padding */
  background-color: rgba(30, 30, 30, 0.95);
  color: #ffffff;
  text-align: center;
`;

export const FeaturesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(250px, 1fr)
  ); /* Responsive grid */
  gap: 20px; /* Increased gap for better spacing */
  margin-top: 30px; /* Reduced margin */
`;

export const Feature = styled.div`
  background-color: rgba(44, 44, 44, 0.8);
  padding: 30px 15px; /* Reduced padding */
  border-radius: 12px;
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.2);
  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 12px 35px rgba(0, 0, 0, 0.3);
  }

  @media (${device.sm}) {
    padding: 20px 10px;
  }
`;

export const FeatureIcon = styled.div`
  font-size: 3rem; /* Reduced icon size */
  color: #bb86fc;
  margin-bottom: 15px; /* Reduced margin */
`;

export const FeatureTitle = styled.h3`
  font-size: 1.5rem; /* Reduced font size */
  margin-bottom: 10px;
  color: #ffffff;
`;

export const FeatureDescription = styled.p`
  font-size: 0.875rem; /* Reduced font size */
  color: #cccccc;
  line-height: 1.5;
`;

/* Authentication Section Styles */
export const AuthSection = styled.section`
  padding: 60px 15px; /* Reduced padding */
  background-image: linear-gradient(
      rgba(44, 44, 44, 0.95),
      rgba(44, 44, 44, 0.95)
    ),
    url('https://picsum.photos/id/5/1920/1080');
  display: flex;
  justify-content: center;
  position: relative; /* To position pseudo-elements */

  /* Decorative Background Shapes */
  &::before,
  &::after {
    content: '';
    position: absolute;
    border-radius: 50%;
    opacity: 0.1;
    background: #bb86fc;
    z-index: -1;
  }

  &::before {
    width: 300px;
    height: 300px;
    top: -150px;
    left: -150px;
  }

  &::after {
    width: 200px;
    height: 200px;
    bottom: -100px;
    right: -100px;
  }
`;

export const AuthContainer = styled.div`
  width: 350px; /* Reduced width */
  max-width: 100%;
  text-align: center;
  color: #ffffff;
  background-color: rgba(
    44,
    44,
    44,
    0.9
  ); /* Enhanced background for AuthContainer */
  padding: 30px 20px;
  border-radius: 15px;
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.3);
  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 12px 35px rgba(0, 0, 0, 0.4);
  }

  @media (${device.md}) {
    width: 500px;
  }

  @media (${device.sm}) {
    width: 90%;
    padding: 20px 15px;
  }

  & > p {
    margin-top: 15px; /* Reduced margin */
    font-size: 0.8rem; /* Reduced font size */

    & > a {
      color: #bb86fc;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

/* Toggle Buttons for Sign In / Sign Up */
export const ToggleContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

interface ToggleButtonProps {
  active: boolean;
}

export const ToggleButton = styled.button<ToggleButtonProps>`
  background: none;
  border: none;
  outline: none;
  padding: 10px 20px;
  font-size: 1rem;
  color: ${({ active }) => (active ? '#bb86fc' : '#cccccc')};
  border-bottom: ${({ active }) =>
    active ? '2px solid #bb86fc' : '2px solid transparent'};
  cursor: pointer;
  transition:
    color 0.3s ease,
    border-bottom 0.3s ease;

  &:hover {
    color: #bb86fc;
  }

  &:first-child {
    margin-right: 20px;
  }

  @media (${device.sm}) {
    font-size: 0.9rem;
    padding: 8px 15px;
    &:first-child {
      margin-right: 10px;
    }
  }
`;

/* Form Styles */
export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FormTitle = styled.h2`
  color: #ffffff;
  font-size: 2rem;
  margin-bottom: 20px;
  font-weight: 700;

  @media (${device.md}) {
    font-size: 1.75rem;
  }

  @media (${device.sm}) {
    font-size: 1.5rem;
  }
`;

export const FormGroup = styled.div`
  width: 100%;
  margin-bottom: 15px;
  text-align: left;
`;

export const Label = styled.label`
  color: #ffffff;
  font-size: 0.9rem;
  margin-bottom: 5px;
  display: block;
`;

export const Input = styled.input`
  width: 100%;
  padding: 10px 15px;
  margin-bottom: 10px;
  border: 1px solid #555555;
  border-radius: 8px;
  background-color: #2c2c2c;
  color: #ffffff;
  font-size: 0.9rem;
  transition:
    border 0.3s ease,
    background-color 0.3s ease;

  &:focus {
    border: 1px solid #bb86fc;
    background-color: #3a3a3a;
    outline: none;
  }

  &::placeholder {
    color: #aaaaaa;
  }
`;

export const SubmitButton = styled.button`
  width: 100%;
  padding: 12px;
  background-color: #bb86fc;
  color: #ffffff;
  border: none;
  border-radius: 8px;
  font-size: 0.95rem;
  cursor: pointer;
  transition:
    background-color 0.3s ease,
    transform 0.3s ease;
  margin-top: 10px;

  &:hover {
    background-color: #9b68c8;
    transform: translateY(-3px);
  }

  &:active {
    transform: translateY(0);
    background-color: #8a4fa1;
  }
`;

export const ForgotPasswordLink = styled.a`
  color: #bb86fc;
  font-size: 0.85rem;
  text-decoration: none;
  margin-bottom: 15px;
  align-self: flex-end;
  display: block;

  &:hover {
    text-decoration: underline;
    color: #9b68c8;
  }
`;

export const Divider = styled.div`
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #555555;
  line-height: 0.1rem;
  margin: 20px 0;
  color: #cccccc;
  font-size: 0.85rem;

  span {
    background: rgba(30, 30, 30, 0.95);
    padding: 0 10px;
  }
`;

export const SocialLoginContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 15px;
  flex-wrap: wrap;
`;

export const SocialLoginButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3a3a3a;
  color: #ffffff;
  width: 48%;
  padding: 10px;
  border-radius: 8px;
  text-decoration: none;
  font-size: 0.9rem;
  transition:
    background-color 0.3s ease,
    transform 0.3s ease;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);

  svg {
    margin-right: 8px;
    font-size: 1.2rem;
  }

  &:hover {
    background-color: #555555;
    transform: translateY(-3px);
  }

  &:active {
    transform: translateY(0);
    background-color: #444444;
  }

  @media (${device.sm}) {
    width: 100%;
    justify-content: center;
  }
`;

export const ErrorMessage = styled.p`
  color: #ff6b6b;
  background-color: rgba(255, 107, 107, 0.1);
  padding: 10px 15px;
  border-radius: 8px;
  width: 100%;
  text-align: center;
  margin-bottom: 15px;
  font-size: 0.85rem;
`;

/* Footer Styles */
export const Footer = styled.footer`
  padding: 50px 15px; /* Reduced padding */
  background-color: rgba(30, 30, 30, 0.95);
  color: #ffffff;
`;

export const FooterContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;

  & > p {
    margin-top: 8px; /* Reduced margin */
    font-size: 0.8rem; /* Reduced font size */
    color: #cccccc;
  }
`;

export const SocialIcons = styled.div`
  margin-top: 15px; /* Reduced margin */
`;

export const SocialIconLink = styled.a`
  color: #ffffff;
  font-size: 1.2rem; /* Reduced icon size */
  margin: 0 8px; /* Reduced margin */
  transition:
    color 0.3s ease,
    transform 0.3s ease;

  &:hover {
    color: #bb86fc;
    transform: translateY(-3px);
  }

  @media (${device.sm}) {
    font-size: 1rem;
    margin: 0 6px;
  }
`;
