import useIsCompletedToday from '../../../hooks/useIsCompletedToday';
import { Tasks } from '../../../models/Tasks';
import { HabitCompletions } from '../../../models/habitCompletions';
import { Habits } from '../../../models/habits';
import { SubTasks } from '../../../models/subTasks';
import IsEmptyMessage from '../IsEmptyMessage/IsEmptyMessage';
import TaskContainer from '../TaskContainer/TaskContainer';
import { StyledSubTaskContainer } from './Styles';
import { isSameDay, isToday, parseISO } from 'date-fns';

interface FilteredListProps {
  tasks: Tasks;
  subTasks: SubTasks;
  habits?: Habits;
  habitCompletions?: HabitCompletions;
  showCompleted: boolean;
  dueToday?: boolean;
  selectionMode?: boolean;
}

const FilteredList = ({
  tasks,
  subTasks,
  habits,
  habitCompletions,
  showCompleted,
  dueToday,
  selectionMode = false,
}: FilteredListProps) => {
  const filteredHabits = habitCompletions?.filter((habitCompletion) =>
    isToday(habitCompletion.date),
  );
  const filteredTasks = tasks.filter((task) => {
    const taskWasCompletedToday =
      task.completed &&
      task.completedAt &&
      useIsCompletedToday(task.completedAt);

    const taskIsDueToday = isToday(parseISO(task.startDate || ''));

    return showCompleted
      ? taskWasCompletedToday
      : dueToday
        ? taskIsDueToday && !task.completed
        : !task.completed;
  });

  const filteredSubTasks = subTasks.filter((subTask) => {
    const subTaskCompletedToday =
      subTask.completed &&
      subTask.completedAt &&
      useIsCompletedToday(subTask.completedAt);

    const subTaskIsDueToday = isToday(parseISO(subTask.startDate || ''));

    return showCompleted
      ? subTaskCompletedToday
      : dueToday
        ? subTaskIsDueToday && !subTask.completed
        : !subTask.completed;
  });

  const getTitleOfCompletedHabit = (habitId: string) => {
    const habit = habits?.find((habit) => habit.id === habitId);
    return habit ? habit.title : '';
  };

  return (
    <>
      {filteredHabits && filteredHabits.length > 0
        ? filteredHabits?.map((habit) => (
            <TaskContainer
              key={habit.id}
              taskId={habit.id}
              title={getTitleOfCompletedHabit(habit.habitId)}
              completed={false}
              selectionMode={selectionMode}
            />
          ))
        : null}
      {showCompleted &&
      filteredTasks.length === 0 &&
      filteredSubTasks.length === 0 &&
      filteredHabits?.length === 0 ? (
        <IsEmptyMessage text="You don't have any completed tasks for today."></IsEmptyMessage>
      ) : filteredTasks.length === 0 &&
        filteredSubTasks.length === 0 &&
        filteredHabits?.length === 0 ? (
        <IsEmptyMessage text="You don't have any to do list items for today."></IsEmptyMessage>
      ) : (
        <>
          {filteredTasks
            .sort((a, b) => b.priority - a.priority)
            .map((task) => (
              <div key={task.id}>
                <TaskContainer
                  key={task.id}
                  taskId={task.id}
                  title={task.title}
                  completed={task.completed}
                  selectionMode={selectionMode}
                />
                {filteredSubTasks
                  .filter((subTask) => subTask.taskId === task.id)
                  .sort((a, b) => b.priority - a.priority)
                  .map((subTask) => (
                    <StyledSubTaskContainer
                      key={subTask.id}
                      taskId={subTask.id}
                      title={subTask.title}
                      parentTaskId={subTask.taskId}
                      completed={subTask.completed}
                      subTask
                      selectionMode={selectionMode}
                    />
                  ))}
              </div>
            ))}
          {showCompleted || dueToday ? (
            <>
              {filteredSubTasks
                .filter((subTask) => {
                  const parentTask = tasks.find(
                    (task) => task.id === subTask.taskId,
                  );
                  return showCompleted
                    ? (parentTask && !parentTask.completed) ||
                        (parentTask &&
                          parentTask.completed &&
                          parentTask.completedAt &&
                          !isSameDay(
                            parseISO(parentTask.completedAt),
                            new Date(),
                          ))
                    : dueToday // the parent being completed causes its subtasks to not show up in today's to do list
                      ? (parentTask &&
                          !isToday(parseISO(parentTask.startDate || ''))) ||
                        (parentTask &&
                          isToday(parseISO(parentTask.startDate || '')) &&
                          parentTask.completed &&
                          parentTask.completedAt && // if the subtask's parent task isn't due today we still want it to show the subtask if it is due today
                          isToday(parseISO(subTask.startDate || '')))
                      : false;
                })
                .map((subTask) => (
                  <StyledSubTaskContainer
                    key={subTask.id}
                    taskId={subTask.id}
                    title={subTask.title}
                    parentTaskId={subTask.taskId}
                    completed={subTask.completed}
                    subTask
                    selectionMode={selectionMode}
                  />
                ))}
            </>
          ) : null}
        </>
      )}
    </>
  );
};

export default FilteredList;
