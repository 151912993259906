import styled from 'styled-components';

export const StyledTitledTextBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;
`;

export const StyledTitledTextBoxTitle = styled.div`
  align-self: flex-start;
  display: flex;
  margin-bottom: 5px;
`;

export const Required = styled.div`
  color: red;
  margin-left: 5px;
`;

export const StyledTextBox = styled.textarea<{ size: string }>`
  width: 100%;
  border: 1px solid ${(props) => props.theme.colors.primaryTextBoxBorder};
  border-radius: 7px;
  background-color: ${(props) => props.theme.colors.primaryTextBoxBackground};
  height: ${(props) => props.size};
`;
