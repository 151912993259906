import { Tasks } from '../../../models/Tasks';
import { HabitCompletions } from '../../../models/habitCompletions';
import { SubTasks } from '../../../models/subTasks';
import { differenceInDays, parseISO, startOfDay } from 'date-fns';

const getDaysSinceSignUp = (createdAt: string) => {
  const today = new Date();
  return (
    differenceInDays(startOfDay(today), startOfDay(parseISO(createdAt))) + 1 ||
    1
  );
};

export const getNumberOfTasksCompleted = (
  tasks: Tasks,
  subTasks: SubTasks,
  habitCompletions: HabitCompletions,
) => {
  return (
    tasks.filter((task) => task.completed).length +
    subTasks.filter((subTask) => subTask.completed).length +
    habitCompletions.length
  );
};

export const getAverageNumberOfTasksCompletedPerDay = (
  tasks: Tasks,
  subTasks: SubTasks,
  habitCompletions: HabitCompletions,
  createdAt: string,
) => {
  return (
    getNumberOfTasksCompleted(tasks, subTasks, habitCompletions) /
    getDaysSinceSignUp(createdAt)
  ).toFixed(2);
};

export const getTotalEstimatedTimeWorked = (
  tasks: Tasks,
  subTasks: SubTasks,
  habitCompletions: HabitCompletions,
) => {
  const tasksAndSubTasksTime = [...tasks, ...subTasks].reduce((total, item) => {
    return item.completed ? total + item.estimatedTime : total;
  }, 0);

  const habitCompletionsTime = habitCompletions.reduce((total, habit) => {
    return total + habit.estimatedTime;
  }, 0);

  return ((tasksAndSubTasksTime + habitCompletionsTime) / 60).toFixed(2);
};

export const getAverageEstimatedTimeWorkedPerDay = (
  tasks: Tasks,
  subTasks: SubTasks,
  habitCompletions: HabitCompletions,
  createdAt: string,
) => {
  const tasksAndSubTasksTime = [...tasks, ...subTasks].reduce((total, item) => {
    return item.completed ? total + item.estimatedTime : total;
  }, 0);

  const habitCompletionsTime = habitCompletions.reduce((total, habit) => {
    return total + habit.estimatedTime;
  }, 0);

  const daysSinceSignUp = getDaysSinceSignUp(createdAt);
  const averagePerDay =
    (tasksAndSubTasksTime + habitCompletionsTime) / daysSinceSignUp / 60;

  return averagePerDay.toFixed(2);
};
