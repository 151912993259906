import { addMinutes, parse } from 'date-fns';

export const combineDateWithTime = (dateString: string, minutes: number) => {
  const date = parse(dateString, 'MM-dd-yyyy', new Date());
  return addMinutes(date, minutes).toString();
};

export const getNumberOfMinutesPassedInDay = (date: string) => {
  const dateObject = new Date(date);
  return dateObject.getHours() * 60 + dateObject.getMinutes();
};

export const dayNameToNumber = (dayName: string): number => {
  const mapping: { [key: string]: number } = {
    Sunday: 0,
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6,
  };
  return mapping[dayName];
};

export const formatMinutesToString = (minutes: number): string => {
  if (minutes < 0 || minutes > 16 * 60) {
    throw new Error('Minutes should be between 0 and 960 (16 hours).');
  }

  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  let formattedTime = '';
  if (hours > 0) {
    formattedTime += `${hours}h`;
  }
  if (remainingMinutes > 0) {
    if (formattedTime.length > 0) {
      formattedTime += ' ';
    }
    formattedTime += `${remainingMinutes} min`;
  }
  return formattedTime || '0 min';
};

// Function to format a date into a time string like '8:00am'
const formatAMPM = (date: Date): string => {
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours || 12; // the hour '0' should be '12'
  const minutesStr = minutes < 10 ? '0' + minutes : minutes;
  return hours + ':' + minutesStr + ampm;
};

export const formatTimeRange = (
  startDate: string,
  estimatedTime: number,
): string => {
  // Parse the startDate into a Date object
  const start = new Date(startDate);

  // Calculate the endDate by adding the estimatedTime in minutes
  const end = new Date(start.getTime() + estimatedTime * 60000);

  // Format the start and end times
  const startTime = formatAMPM(start);
  const endTime = formatAMPM(end);

  // Combine start and end times into the final string
  return `${startTime} - ${endTime}`;
};
