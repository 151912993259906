import styled from 'styled-components';
import { device } from '../../../../themes/breakpoints';

export const StatsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-width: 200px;
  color: ${(props) => props.theme.colors.primaryText};
  background-color: ${(props) => props.theme.colors.primary};
  border-radius: 5px;
  margin-right: 15px;
  padding: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);

  @media ${device.sm} {
    margin-right: 0;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StatsNumberText = styled.p`
  font-size: 50px;
  margin: 0;
  letter-spacing: 2px;
  font-family: system-ui;
`;

export const StatsTitleText = styled.p`
  width: 125px;
  font-size: 12px;
  margin: 0;
  margin-top: 10px;
`;
