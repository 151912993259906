import { createContext, useState } from 'react';
import {
  HabitModalFormValues,
  IFormContext,
} from '../models/formModalContexts';
import { format } from 'date-fns';
import {
  combineDateWithTime,
  getNumberOfMinutesPassedInDay,
} from '../helpers/time';
import { useAppDispatch, useAppSelector } from '../store/store';
import { selectUser } from '../features/user/userSlice';
import { selectHabits } from '../features/habits/habitsSlice';
import {
  addUserHabit,
  completeUserHabit,
  deleteUserHabit,
  editUserHabit,
} from '../api/habits';
import { UseFormReset, UseFormSetValue } from 'react-hook-form';

interface HabitTemplate {
  title: string;
  estimatedTime: number;
  daysOfWeek: string | null;
  intervalCount: number;
  intervalType: string;
}

interface IHabitFormContext extends IFormContext<HabitModalFormValues> {
  handlePopulateTemplateHabitData: (templateData: HabitTemplate) => void;
}

const defaultContextValue = {
  formModalOpen: false,
  setFormModalOpen: () => {},
  selectedItemId: '',
  setSelectedItemId: () => {},
  editMode: false,
  setEditMode: () => {},
  fillFormOnEdit: () => {},
  handleFormSubmitButtonClicked: () => {},
  handleEditButtonClicked: () => {},
  handleCompleteItem: () => {},
  handleDeleteItem: () => {},
  onFormModalOpen: () => {},
  onFormModalClose: () => {},
  handlePopulateTemplateHabitData: () => {},
};

export const HabitFormContext =
  createContext<IHabitFormContext>(defaultContextValue);

export const HabitFormContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const dispatch = useAppDispatch();
  const { id } = useAppSelector(selectUser);
  const habits = useAppSelector(selectHabits);
  const [formModalOpen, setFormModalOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [templateHabit, setTemplateHabit] = useState<HabitTemplate | null>(
    null,
  );

  const clearContextValues = () => {
    setFormModalOpen(false);
    setSelectedItemId('');
    setEditMode(false);
    setTemplateHabit(null);
  };

  const fillFormOnEdit = (
    setValue: UseFormSetValue<HabitModalFormValues>,
    reset: UseFormReset<HabitModalFormValues>,
  ) => {
    if (editMode) {
      const habit = habits.find((habit) => habit.id === selectedItemId);
      if (habit) {
        setValue('title', habit.title);
        setValue('description', habit.description);
        setValue('startDate', format(habit.startDate, 'MM-dd-yyyy'));
        setValue('estimatedTime', habit.estimatedTime);
        setValue('projectId', habit.projectId);
        setValue(
          'startTime',
          getNumberOfMinutesPassedInDay(habit.startDate).toString(),
        );
        if (habit.daysOfWeek) {
          setValue('daysOfWeek', habit.daysOfWeek);
        }
        setValue('intervalCount', habit.intervalCount);
        setValue('intervalType', habit.intervalType);
      }
    } else {
      if (templateHabit) {
        setValue('title', templateHabit.title);
        setValue('estimatedTime', templateHabit.estimatedTime);
        setValue('daysOfWeek', templateHabit.daysOfWeek);
        setValue('intervalCount', templateHabit.intervalCount);
        setValue('intervalType', templateHabit.intervalType);
      } else {
        reset();
      }
    }
  };

  const handleFormSubmitButtonClicked = (data: HabitModalFormValues) => {
    if (editMode) {
      dispatch(
        editUserHabit({
          ...data,
          startDate: combineDateWithTime(
            data.startDate,
            Number(data.startTime),
          ),
          userId: id,
          daysOfWeek: data.daysOfWeek,
          estimatedTime: Number(data.estimatedTime),
          id: selectedItemId,
          order: 0,
        }),
      );
    } else {
      dispatch(
        addUserHabit({
          ...data,
          startDate: combineDateWithTime(
            data.startDate,
            Number(data.startTime),
          ),
          userId: id,
          daysOfWeek: data.daysOfWeek,
          estimatedTime: Number(data.estimatedTime),
          order: 0,
        }),
      );
    }
    clearContextValues();
  };

  const handleEditButtonClicked = (itemId: string) => {
    onFormModalOpen();
    setSelectedItemId(itemId);
    setEditMode(true);
  };

  const handleCompleteItem = (itemId: string) => {
    const estimatedTime = habits.find((habit) => habit.id === itemId)
      ?.estimatedTime;
    if (estimatedTime) {
      dispatch(
        completeUserHabit({ userId: id, habitId: itemId, estimatedTime }),
      );
    }
  };

  const handleDeleteItem = (itemId: string) => {
    dispatch(deleteUserHabit(itemId));
  };

  const onFormModalOpen = () => {
    setFormModalOpen(true);
  };

  const onFormModalClose = () => {
    clearContextValues();
  };

  const handlePopulateTemplateHabitData = (templateData: HabitTemplate) => {
    setTemplateHabit(templateData);
    onFormModalOpen();
  };

  return (
    <HabitFormContext.Provider
      value={{
        formModalOpen,
        setFormModalOpen,
        selectedItemId,
        setSelectedItemId,
        editMode,
        setEditMode,
        fillFormOnEdit,
        handleFormSubmitButtonClicked,
        handleEditButtonClicked,
        handleCompleteItem,
        handleDeleteItem,
        onFormModalOpen,
        onFormModalClose,
        handlePopulateTemplateHabitData,
      }}
    >
      {children}
    </HabitFormContext.Provider>
  );
};
