import styled from 'styled-components';
import { device } from '../../themes/breakpoints';
import InfoContainer from '../components/InfoContainer/InfoContainer';
import StatsInfo from './components/StatsInfo/StatsInfo';

export const StyledProjectsContainer = styled.div``;

export const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  width: 100%;
  height: calc(100vh - 20px);
  gap: 10px;
`;

export const DashboardStatsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;

  @media ${device.sm} {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Creates two columns */
    grid-template-rows: auto auto; /* Makes rows grow as needed */
    gap: 10px;
  }
`;

export const TopDashboardRowStatsInfo = styled(StatsInfo)`
  flex: 1;
`;

export const DashboardRow = styled.div`
  display: flex;
  flex-direction: row;
  flex: 2;

  @media ${device.sm} {
    flex-direction: column;
    width: 100%;
    gap: 10px;
  }
`;

export const MiddleDashboardRow = styled(DashboardRow)`
  display: flex;
  justify-content: space-between;
  gap: 15px;
  margin-right: 15px;
`;

export const MiddleDashboardRowInfoContainer = styled(InfoContainer)`
  flex: 1;
`;

export const ScrollableContainer = styled.div`
  overflow-y: scroll;
  max-height: 270px;
`;
