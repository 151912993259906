import { AddButtonContainer, AddButtonText } from './Styles';
import newIcon from './../../../../assets/icons/newIcon.png';
import IconButton from '../../../components/IconButton/IconButton';

interface AddButtonProps {
  isProject?: boolean;
  onClick: () => void;
  title?: string;
}

const AddButton = ({ isProject = false, onClick, title }: AddButtonProps) => {
  return (
    <AddButtonContainer onClick={() => onClick()}>
      <IconButton onClick={() => {}} src={newIcon} alt="new" />
      <AddButtonText>
        {title ? title : `Add a ${isProject ? 'project' : 'task'}`}
      </AddButtonText>
    </AddButtonContainer>
  );
};

export default AddButton;
