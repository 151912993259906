import NavigationSidebar from '../NavigationSideBar/NavigationSideBar';
import PageBody from '../StyledBody/StyledBody';

interface PageContainerProps {
  children: React.ReactNode;
}

const PageContainer = ({ children }: PageContainerProps) => {
  return (
    <div>
      <NavigationSidebar />
      <PageBody>{children}</PageBody>
    </div>
  );
};

export default PageContainer;
