import axios from 'axios';
import { z } from 'zod';
import { UserSchema } from '../models/user';
import { apiErrorHandler } from './apiErrorHandler';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { determineCorrectServerUrl } from './util';

const SignUpUserRequestSchema = z.object({
  email: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  password: z.string(),
});

const SignInUserRequestSchema = z.object({
  email: z.string(),
  password: z.string(),
});

export type SignUpUserRequest = z.infer<typeof SignUpUserRequestSchema>;

export type SignInUserRequest = z.infer<typeof SignInUserRequestSchema>;

export const signUpUser = createAsyncThunk(
  'user/signUpUser',
  async ({ email, firstName, lastName, password }: SignUpUserRequest) => {
    try {
      const validatedUser = SignUpUserRequestSchema.parse({
        email,
        firstName,
        lastName,
        password,
      });

      const newUser = (
        await axios.post(
          `${determineCorrectServerUrl()}/users`,
          validatedUser,
          { withCredentials: true },
        )
      ).data;

      const validatedNewUser = UserSchema.parse({
        isAuthenticated: true,
        ...newUser,
      });
      return validatedNewUser;
    } catch (error) {
      apiErrorHandler(error);
    }
  },
);

export const signInUser = createAsyncThunk(
  'user/signInUser',
  async ({ email, password }: SignInUserRequest) => {
    try {
      const { email: validatedEmail, password: validatedPassword } =
        SignInUserRequestSchema.parse({
          email,
          password,
        });

      const user = (
        await axios.get(
          `${determineCorrectServerUrl()}/users/email/${validatedEmail}?password=${validatedPassword}`,
          {
            withCredentials: true,
          },
        )
      ).data;

      const validatedUser = UserSchema.parse({
        isAuthenticated: true,
        ...user,
      });
      return validatedUser;
    } catch (error) {
      apiErrorHandler(error);
    }
  },
);

export const validateRefreshToken = createAsyncThunk(
  'user/validateRefreshToken',
  async () => {
    try {
      const user = (
        await axios.post(
          `${determineCorrectServerUrl()}/users/refreshToken`,
          {},
          { withCredentials: true },
        )
      ).data;

      const validatedUser = UserSchema.parse({
        isAuthenticated: true,
        ...user,
      });
      return validatedUser;
    } catch (error) {
      apiErrorHandler(error);
    }
  },
);

export const logOutUser = createAsyncThunk('user/logOutUser', async () => {
  try {
    await axios.post(
      `${determineCorrectServerUrl()}/users/logOut`,
      {},
      { withCredentials: true },
    );
  } catch (error) {
    apiErrorHandler(error);
  }
});
