import styled from 'styled-components';
import YellowButton from '../../../components/YellowButton/YellowButton';
import { device } from '../../../../themes/breakpoints';

export const StyledHabitModalContainer = styled.div`
  display: flex;
  gap: 20px;

  @media ${device.md} {
    flex-direction: column;
  }
`;

export const StyledLeftColumn = styled.div`
  width: 100%;
  flex: 2;
`;

export const StyledRightColumn = styled.div`
  width: 100%;
  flex: 1;
`;

export const RightAlignedExtraTopMarginYellowButton = styled(YellowButton)`
  float: right;

  @media ${device.md} {
    margin-top: 20px;
  }
`;
