import { useEffect, useState } from 'react';
import {
  HabitRecurrenceSettingContainer,
  HabitRecurrenceSettingRow,
  StyledCounterContainer,
  StyledCounterInput,
  StyledIntervalSelect,
  StyledTextContainer,
  StyledWeeklyDayOptionsContainer,
} from './Styles';
import WeeklyDayOption from '../WeeklyDayOption/WeeklyDayOption';
import {
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { HabitModalFormValues } from '../../../../models/formModalContexts';

const defaultSelectedDays = {
  Monday: false,
  Tuesday: false,
  Wednesday: false,
  Thursday: false,
  Friday: false,
  Saturday: false,
  Sunday: false,
};

type DayOfWeek =
  | 'Monday'
  | 'Tuesday'
  | 'Wednesday'
  | 'Thursday'
  | 'Friday'
  | 'Saturday'
  | 'Sunday';

type DayInfo = {
  day: DayOfWeek;
  dayShortHand: string;
};

type DayList = DayInfo[];

interface HabitRecurrenceSettingProps {
  register: UseFormRegister<HabitModalFormValues>;
  watch: UseFormWatch<HabitModalFormValues>;
  setValue: UseFormSetValue<HabitModalFormValues>;
  defaultRecurrenceSetting?: string;
}

const HabitRecurrenceSetting = ({
  register,
  watch,
  setValue,
  defaultRecurrenceSetting,
}: HabitRecurrenceSettingProps) => {
  const dayList: DayList = [
    { day: 'Sunday', dayShortHand: 'S' },
    { day: 'Monday', dayShortHand: 'M' },
    { day: 'Tuesday', dayShortHand: 'T' },
    { day: 'Wednesday', dayShortHand: 'W' },
    { day: 'Thursday', dayShortHand: 'T' },
    { day: 'Friday', dayShortHand: 'F' },
    { day: 'Saturday', dayShortHand: 'S' },
  ];

  const formatSelectedDaysAsString = (selectedDays: {
    Monday: boolean;
    Tuesday: boolean;
    Wednesday: boolean;
    Thursday: boolean;
    Friday: boolean;
    Saturday: boolean;
    Sunday: boolean;
  }) => {
    const formattedDays = Object.entries(selectedDays)
      .filter((day) => day[1])
      .map((day) => day[0])
      .join(',');
    return formattedDays;
  };

  const formatSelectedDaysAsObject = (daysString: string) => {
    if (daysString === '') {
      return defaultSelectedDays;
    }

    const dayArray = daysString.split(',');

    return {
      Monday: dayArray.includes('Monday'),
      Tuesday: dayArray.includes('Tuesday'),
      Wednesday: dayArray.includes('Wednesday'),
      Thursday: dayArray.includes('Thursday'),
      Friday: dayArray.includes('Friday'),
      Saturday: dayArray.includes('Saturday'),
      Sunday: dayArray.includes('Sunday'),
    };
  };

  const counter = watch('intervalCount') || 1;
  const interval = watch('intervalType');

  const [selectedDays, setSelectedDays] = useState<{
    [key in DayOfWeek]: boolean;
  }>(formatSelectedDaysAsObject(defaultRecurrenceSetting || ''));

  const handleWeeklyDayClick = (day: DayOfWeek) => {
    const newSelectedDays = { ...selectedDays, [day]: !selectedDays[day] };
    setSelectedDays(newSelectedDays),
      setValue('daysOfWeek', formatSelectedDaysAsString(newSelectedDays));
  };

  useEffect(() => {
    const formattedDays = formatSelectedDaysAsObject(
      defaultRecurrenceSetting || '',
    );
    setSelectedDays(formattedDays);
  }, [defaultRecurrenceSetting]);

  return (
    <HabitRecurrenceSettingContainer>
      <HabitRecurrenceSettingRow>
        <StyledTextContainer>Repeat every</StyledTextContainer>
        <StyledCounterContainer>
          <StyledCounterInput type="text" {...register('intervalCount')} />
        </StyledCounterContainer>
        <StyledIntervalSelect {...register('intervalType')}>
          <option value={'daily'}>{counter > 1 ? 'days' : 'day'}</option>
          <option value={'weekly'}>{counter > 1 ? 'weeks' : 'week'}</option>
          <option value={'monthly'}>{counter > 1 ? 'months' : 'month'}</option>
          <option value={'yearly'}>{counter > 1 ? 'years' : 'year'}</option>
        </StyledIntervalSelect>
      </HabitRecurrenceSettingRow>
      <HabitRecurrenceSettingRow>
        {interval === 'weekly' ? (
          <StyledWeeklyDayOptionsContainer>
            {dayList.map((day) => (
              <WeeklyDayOption
                onClick={() => handleWeeklyDayClick(day.day)}
                day={day.day}
                dayShortHand={day.dayShortHand}
                isSelected={selectedDays[day.day]}
              />
            ))}
          </StyledWeeklyDayOptionsContainer>
        ) : null}
      </HabitRecurrenceSettingRow>
    </HabitRecurrenceSettingContainer>
  );
};

export default HabitRecurrenceSetting;
