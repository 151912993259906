import { format } from 'date-fns';
import { createContext, useState } from 'react';
import { editUserSubTask } from '../api/subTasks';
import { editUserTask } from '../api/tasks';
import { selectSubTasks } from '../features/subTasks/subTasksSlice';
import { selectTasks } from '../features/tasks/tasksSlice';
import { useAppDispatch, useAppSelector } from '../store/store';
import { Tasks } from '../models/Tasks';
import { SubTasks } from '../models/subTasks';
import isScheduledToday from '../helpers/scheduling';

interface SelectedTasksContextType {
  selectedTasks: string[];
  addSelectedTask: (taskId: string) => void;
  removeSelectedTask: (taskId: string) => void;
  submitSelectedTasks: () => void;
}

export const SelectedTasksContext = createContext<SelectedTasksContextType>({
  selectedTasks: [],
  addSelectedTask: () => {},
  removeSelectedTask: () => {},
  submitSelectedTasks: () => {},
});

interface SelectedTasksProviderProps {
  children: React.ReactNode;
}

const populateInitialSelectedTasks = (tasks: Tasks, subTasks: SubTasks) => {
  const initialSelectedTasks: string[] = [];
  tasks.map((task) => {
    if (isScheduledToday(task.id, tasks, subTasks)) {
      initialSelectedTasks.push(task.id);
    }
  });

  subTasks.map((subTask) => {
    if (isScheduledToday(subTask.id, tasks, subTasks)) {
      initialSelectedTasks.push(subTask.id);
    }
  });

  return initialSelectedTasks;
};

export const SelectedTasksContextProvider = ({
  children,
}: SelectedTasksProviderProps) => {
  const dispatch = useAppDispatch();
  const tasks = useAppSelector(selectTasks);
  const subTasks = useAppSelector(selectSubTasks);
  const [selectedTasks, setSelectedTasks] = useState<string[]>(
    populateInitialSelectedTasks(tasks, subTasks),
  );
  const [removedTasks, setRemovedTasks] = useState<string[]>([]);

  const addSelectedTask = (taskId: string) => {
    setSelectedTasks((prevState) => [...prevState, taskId]);
  };

  const removeSelectedTask = (taskId: string) => {
    setSelectedTasks((prevState) => prevState.filter((id) => id !== taskId));
    setRemovedTasks((prevState) => [...prevState, taskId]);
  };

  const submitSelectedTasks = () => {
    selectedTasks.map((taskId) => {
      const task = tasks.find((task) => task.id === taskId);
      const subTask = subTasks.find((subTask) => subTask.id === taskId);

      if (task) {
        dispatch(
          editUserTask({
            ...task,
            taskId,
            startDate: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
          }),
        );
      } else {
        if (subTask) {
          dispatch(
            editUserSubTask({
              ...subTask,
              startDate: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
            }),
          );
        }
      }
      setSelectedTasks([]);
    });

    removedTasks.map((taskId) => {
      const task = tasks.find((task) => task.id === taskId);
      const subTask = subTasks.find((subTask) => subTask.id === taskId);

      if (task) {
        dispatch(
          editUserTask({
            ...task,
            taskId,
            startDate: null,
          }),
        );
      } else {
        if (subTask) {
          dispatch(
            editUserSubTask({
              ...subTask,
              startDate: null,
            }),
          );
        }
      }
      setRemovedTasks([]);
    });
  };

  return (
    <SelectedTasksContext.Provider
      value={{
        selectedTasks,
        addSelectedTask,
        removeSelectedTask,
        submitSelectedTasks,
      }}
    >
      {children}
    </SelectedTasksContext.Provider>
  );
};
