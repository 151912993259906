import { createSlice } from '@reduxjs/toolkit';
import { InitialProjectsState, RootState, Status } from '../RootState';
import {
  addUserProject,
  completeUserProject,
  deleteUserProject,
  editUserProject,
  hydrateUserProjects,
  reorderProjects,
} from '../../api/projects';
import { isEqual } from 'lodash';

const initialState: InitialProjectsState = {
  projects: [],
  status: Status.IDLE,
  error: null,
};

export const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    reorderProjectsReduxState: (state, action) => {
      if (!isEqual(state.projects, action.payload)) {
        state.projects = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(hydrateUserProjects.fulfilled, (state, action) => {
      state.status = Status.SUCCEEDED;
      if (action.payload) {
        state.projects = action.payload;
      }
    });
    builder.addCase(addUserProject.fulfilled, (state, action) => {
      if (action.payload) {
        state.projects.push(action.payload);
        state.status = Status.SUCCEEDED;
      }
    });
    builder.addCase(editUserProject.fulfilled, (state, action) => {
      if (action.payload) {
        const { id } = action.payload;
        const projectIndex = state.projects.findIndex(
          (project) => project.id === id,
        );
        if (projectIndex !== -1) {
          state.projects[projectIndex] = action.payload;
          state.status = Status.SUCCEEDED;
        }
      }
    });
    builder.addCase(completeUserProject.fulfilled, (state, action) => {
      if (action.payload) {
        const projectIdToComplete = action.payload.id;
        state.projects = state.projects.filter(
          (project) => project.id !== projectIdToComplete,
        );
        state.status = Status.SUCCEEDED;
      }
    });
    builder.addCase(deleteUserProject.fulfilled, (state, action) => {
      if (action.payload) {
        const projectIdToDelete = action.payload.id;
        state.projects = state.projects.filter(
          (project) => project.id !== projectIdToDelete,
        );
        state.status = Status.SUCCEEDED;
      }
    });
    builder.addCase(reorderProjects.fulfilled, (state, action) => {
      if (action.payload) {
        state.projects = action.payload;
        state.status = Status.SUCCEEDED;
      }
    });
  },
});

export const selectProjects = (state: RootState) =>
  state.projectsState.projects;

export const selectProjectsStatus = (state: RootState) =>
  state.projectsState.status;

export const { reorderProjectsReduxState } = projectsSlice.actions;

export default projectsSlice.reducer;
