import { FieldValues, Path, UseFormRegister } from 'react-hook-form';
import {
  StyledTitledTextBoxContainer,
  StyledTitledTextBoxTitle,
  Required,
  StyledTextBox,
} from './Styles';

type TitledTextBoxProps<TFormValues extends FieldValues> = {
  title: string;
  label: Path<TFormValues>;
  register: UseFormRegister<TFormValues>;
  required?: boolean;
  onClick?: () => void;
  size?: 'large';
  dropdownOptions?: { title: string; value: number | string }[];
  password?: boolean;
  placeholder?: string;
  className?: string;
};

const TitledTextBox = <TFormValues extends FieldValues>({
  title,
  label,
  register,
  required,
  onClick,
  size,
  dropdownOptions,
  password,
  placeholder,
  className,
}: TitledTextBoxProps<TFormValues>) => {
  const dateValidationRules =
    label === 'startDate'
      ? {
          pattern: {
            value: /^(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])-\d{4}$/,
            message: 'Invalid date format. Please use MM-DD-YYYY format.',
          },
        }
      : undefined;

  return (
    <StyledTitledTextBoxContainer className={className} onClick={onClick}>
      <StyledTitledTextBoxTitle>
        {title} {required && <Required>{'*'}</Required>}
      </StyledTitledTextBoxTitle>
      <StyledTextBox
        as={
          size === 'large'
            ? 'textarea'
            : dropdownOptions && dropdownOptions?.length > 0
              ? 'select'
              : 'input'
        }
        size={size === 'large' ? '120px' : '40px'}
        type={password ? 'password' : 'text'}
        {...register(label, {
          required,
          maxLength: size === 'large' ? 1024 : 191,
          pattern: dateValidationRules?.pattern,
        })}
        placeholder={placeholder}
      >
        {dropdownOptions &&
          dropdownOptions?.length > 0 &&
          dropdownOptions?.map((option, index) => (
            <option key={index} value={option.value}>
              {option.title}
            </option>
          ))}
      </StyledTextBox>
    </StyledTitledTextBoxContainer>
  );
};

export default TitledTextBox;
