import { z } from 'zod';

export const HabitSchema = z.object({
  id: z.string(),
  userId: z.string(),
  projectId: z.string(),
  title: z.string(),
  description: z.string(),
  estimatedTime: z.number(),
  startDate: z.string(),
  intervalType: z.string(),
  intervalCount: z.number(),
  daysOfWeek: z.string().nullable(),
  order: z.number(),
  streak: z.number(),
});

export type Habit = z.infer<typeof HabitSchema>;

export const HabitsSchema = z.array(HabitSchema);

export type Habits = z.infer<typeof HabitsSchema>;
