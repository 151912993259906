import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Dashboard from './pages/Dashboard/Dashboard';
import Landing from './pages/Landing/Landing';
import PageNotFound from './pages/PageNotFound/PageNotFound';
import { Provider } from 'react-redux';
import { store } from './store/store';
import GeneralThemeProvider from './themes/ThemeProvider';
import Calendar from './pages/CalendarPage/CalendarPage';
import Settings from './pages/Settings/Settings';
import GlobalStyles from './themes/GlobalStyles';
import { GenericTaskFormContextProvider } from './contexts/GenericTaskFormContext';
import AuthProvider from './pages/components/AuthProvider/AuthProvider';
import ProtectedRoute from './pages/components/ProtectedRoute/ProtectedRoute';
import ProjectsPage from './pages/Projects/ProjectsPage';
import HabitsPage from './pages/HabitsPage/HabitsPage';
import { ProjectFormContextProvider } from './contexts/ProjectFormContext';
import { HabitFormContextProvider } from './contexts/HabitFormContext';

function App() {
  return (
    <div className="App">
      <GeneralThemeProvider>
        <Provider store={store}>
          <GlobalStyles />
          <ProjectFormContextProvider>
            <HabitFormContextProvider>
              <GenericTaskFormContextProvider>
                <BrowserRouter>
                  <AuthProvider>
                    <Routes>
                      <Route path="/" element={<Landing />} />
                      <Route
                        path="/dashboard"
                        element={
                          <ProtectedRoute>
                            <Dashboard />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/projects"
                        element={
                          <ProtectedRoute>
                            <ProjectsPage />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/habits"
                        element={
                          <ProtectedRoute>
                            <HabitsPage />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/calendar"
                        element={
                          <ProtectedRoute>
                            <Calendar />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/settings"
                        element={
                          <ProtectedRoute>
                            <Settings />
                          </ProtectedRoute>
                        }
                      />
                      <Route path="*" element={<PageNotFound />} />
                    </Routes>
                  </AuthProvider>
                </BrowserRouter>
              </GenericTaskFormContextProvider>
            </HabitFormContextProvider>
          </ProjectFormContextProvider>
        </Provider>
      </GeneralThemeProvider>
    </div>
  );
}

export default App;
