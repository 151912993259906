// Landing.tsx

import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { selectUser } from '../../features/user/userSlice';
import { useNavigate } from 'react-router-dom';

import {
  StyledLandingContainer,
  Navbar,
  NavLogo,
  NavLinks,
  NavLinkItem,
  HeroSection,
  HeroContent,
  Headline,
  SubHeadline,
  CTAButton,
  FeaturesSection,
  FeaturesWrapper,
  Feature,
  FeatureIcon,
  FeatureTitle,
  FeatureDescription,
  AuthSection,
  AuthContainer,
  Footer,
  FooterContent,
  SocialIcons,
  SocialIconLink,
  ToggleContainer,
  ToggleButton,
  Form,
  FormTitle,
  FormGroup,
  Label,
  Input,
  SubmitButton,
  ForgotPasswordLink,
  ErrorMessage,
} from './Styles';

// Import React Icons
import { MdAssignment, MdTimeline, MdCalendarToday } from 'react-icons/md';
import { FaFacebookF, FaTwitter, FaLinkedinIn } from 'react-icons/fa';
import { signInUser, signUpUser } from '../../api/user';

const Landing: React.FC = () => {
  const dispatch = useAppDispatch();
  const [userSigningIn, setUserSigningIn] = useState(true);
  const { isAuthenticated } = useAppSelector(selectUser);
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/dashboard');
    }
  }, [isAuthenticated, navigate]);

  const handleSwitch = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    setUserSigningIn(!userSigningIn);
  };

  // Placeholder state and handlers for Sign In and Sign Up forms
  const [signInEmail, setSignInEmail] = useState('');
  const [signInPassword, setSignInPassword] = useState('');
  const [signInError, setSignInError] = useState('');

  const [signUpName, setSignUpName] = useState('');
  const [signUpEmail, setSignUpEmail] = useState('');
  const [signUpPassword, setSignUpPassword] = useState('');
  const [signUpError, setSignUpError] = useState('');

  const handleSignInSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!signInEmail || !signInPassword) {
      setSignInError('Please enter both email and password.');
      return;
    }
    dispatch(signInUser({ email: signInEmail, password: signInPassword })).then(
      ({ payload }) => {
        if (payload) {
          navigate('/dashboard');
        } else {
          alert(
            'There was a problem during sign in. Please double check your information.',
          );
        }
      },
    );
    setSignInError('');
  };

  const handleSignUpSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!signUpName || !signUpEmail || !signUpPassword) {
      setSignUpError('Please fill in all fields.');
      return;
    }
    dispatch(
      signUpUser({
        email: signUpEmail,
        password: signUpPassword,
        firstName: signUpName.split(' ')[0],
        lastName: signUpName.split(' ')[1],
      }),
    ).then(({ payload }) => {
      if (payload) {
        navigate('/dashboard');
      } else {
        alert(
          'There was a problem during sign in. Please double check your information.',
        );
      }
    });
    setSignUpError('');
  };

  return (
    <StyledLandingContainer>
      {/* Navbar */}
      <Navbar>
        <NavLogo href="#">Jumble</NavLogo>
        <NavLinks>
          <NavLinkItem href="#features">Features</NavLinkItem>
          <NavLinkItem href="#auth-section">Get Started</NavLinkItem>
        </NavLinks>
      </Navbar>

      {/* Hero Section */}
      <HeroSection>
        <HeroContent>
          <Headline>Streamline Your Workflow</Headline>
          <SubHeadline>
            All your tasks, projects, and schedules in one place.
          </SubHeadline>
          <CTAButton
            onClick={() => {
              document
                .getElementById('auth-section')
                ?.scrollIntoView({ behavior: 'smooth' });
            }}
          >
            Get Started
          </CTAButton>
        </HeroContent>
      </HeroSection>

      {/* Features Section */}
      <FeaturesSection id="features">
        <Headline>Features</Headline>
        <FeaturesWrapper>
          <Feature>
            <FeatureIcon aria-hidden="true">
              <MdAssignment />
            </FeatureIcon>
            <FeatureTitle>Task Management</FeatureTitle>
            <FeatureDescription>
              Organize your tasks and stay on top of your work.
            </FeatureDescription>
          </Feature>
          <Feature>
            <FeatureIcon aria-hidden="true">
              <MdTimeline />
            </FeatureIcon>
            <FeatureTitle>Project Tracking</FeatureTitle>
            <FeatureDescription>
              Keep track of project progress with ease.
            </FeatureDescription>
          </Feature>
          <Feature>
            <FeatureIcon aria-hidden="true">
              <MdCalendarToday />
            </FeatureIcon>
            <FeatureTitle>Integrated Calendar</FeatureTitle>
            <FeatureDescription>
              Manage your schedule and meetings efficiently.
            </FeatureDescription>
          </Feature>
        </FeaturesWrapper>
      </FeaturesSection>

      {/* Authentication Section */}
      <AuthSection id="auth-section">
        <AuthContainer>
          <ToggleContainer>
            <ToggleButton
              active={userSigningIn}
              onClick={() => setUserSigningIn(true)}
            >
              Sign In
            </ToggleButton>
            <ToggleButton
              active={!userSigningIn}
              onClick={() => setUserSigningIn(false)}
            >
              Sign Up
            </ToggleButton>
          </ToggleContainer>
          {userSigningIn ? (
            <Form onSubmit={handleSignInSubmit}>
              <FormTitle>Sign In</FormTitle>
              {signInError && <ErrorMessage>{signInError}</ErrorMessage>}
              <FormGroup>
                <Label htmlFor="signInEmail">Email Address</Label>
                <Input
                  type="email"
                  id="signInEmail"
                  value={signInEmail}
                  onChange={(e) => setSignInEmail(e.target.value)}
                  required
                  placeholder="Enter your email"
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="signInPassword">Password</Label>
                <Input
                  type="password"
                  id="signInPassword"
                  value={signInPassword}
                  onChange={(e) => setSignInPassword(e.target.value)}
                  required
                  placeholder="Enter your password"
                />
              </FormGroup>
              <ForgotPasswordLink href="#">
                Forgot your password?
              </ForgotPasswordLink>
              <SubmitButton type="submit">Sign In</SubmitButton>
            </Form>
          ) : (
            <Form onSubmit={handleSignUpSubmit}>
              <FormTitle>Sign Up</FormTitle>
              {signUpError && <ErrorMessage>{signUpError}</ErrorMessage>}
              <FormGroup>
                <Label htmlFor="signUpName">Full Name</Label>
                <Input
                  type="text"
                  id="signUpName"
                  value={signUpName}
                  onChange={(e) => setSignUpName(e.target.value)}
                  required
                  placeholder="Enter your full name"
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="signUpEmail">Email Address</Label>
                <Input
                  type="email"
                  id="signUpEmail"
                  value={signUpEmail}
                  onChange={(e) => setSignUpEmail(e.target.value)}
                  required
                  placeholder="Enter your email"
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="signUpPassword">Password</Label>
                <Input
                  type="password"
                  id="signUpPassword"
                  value={signUpPassword}
                  onChange={(e) => setSignUpPassword(e.target.value)}
                  required
                  placeholder="Create a password"
                />
              </FormGroup>
              <SubmitButton type="submit">Sign Up</SubmitButton>
            </Form>
          )}
          <p>
            {userSigningIn
              ? "Don't have an account?"
              : 'Already have an account?'}{' '}
            <a href="#" onClick={handleSwitch}>
              {userSigningIn ? 'Sign Up' : 'Sign In'}
            </a>
          </p>
        </AuthContainer>
      </AuthSection>

      {/* Footer */}
      <Footer>
        <FooterContent>
          <NavLogo href="#">Jumble</NavLogo>
          <p>© {new Date().getFullYear()} Jumble. All rights reserved.</p>
          <SocialIcons>
            <SocialIconLink href="#" target="_blank" aria-label="Facebook">
              <FaFacebookF />
            </SocialIconLink>
            <SocialIconLink href="#" target="_blank" aria-label="Twitter">
              <FaTwitter />
            </SocialIconLink>
            <SocialIconLink href="#" target="_blank" aria-label="LinkedIn">
              <FaLinkedinIn />
            </SocialIconLink>
          </SocialIcons>
        </FooterContent>
      </Footer>
    </StyledLandingContainer>
  );
};

export default Landing;
