import React, { useState } from 'react';
import {
  SidebarContainer,
  Logo,
  NavList,
  NavItem,
  LogOutButton,
  IconWrapper,
  HamburgerMenu,
  MobileNavDropdown,
  CloseIcon,
} from './Styles';
import { useNavigate } from 'react-router-dom';
import { logOutUser } from '../../../api/user';
import { useAppDispatch } from '../../../store/store';
import {
  FaSignOutAlt,
  FaTachometerAlt,
  FaProjectDiagram,
  FaTasks,
  FaCalendarAlt,
  FaCog,
} from 'react-icons/fa';

const NavigationSidebar = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const logOut = () => {
    dispatch(logOutUser());
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <SidebarContainer>
      <div>
        <Logo onClick={() => navigate('/dashboard')}>Jumble</Logo>

        <NavList>
          <NavItem onClick={() => navigate('/dashboard')}>
            <IconWrapper>
              <FaTachometerAlt />
            </IconWrapper>
            Dashboard
          </NavItem>
          <NavItem onClick={() => navigate('/projects')}>
            <IconWrapper>
              <FaProjectDiagram />
            </IconWrapper>
            Projects
          </NavItem>
          <NavItem onClick={() => navigate('/habits')}>
            <IconWrapper>
              <FaTasks />
            </IconWrapper>
            Habits
          </NavItem>
          <NavItem onClick={() => navigate('/calendar')}>
            <IconWrapper>
              <FaCalendarAlt />
            </IconWrapper>
            Calendar
          </NavItem>
          <NavItem onClick={() => navigate('/settings')}>
            <IconWrapper>
              <FaCog />
            </IconWrapper>
            Settings
          </NavItem>
        </NavList>
      </div>

      <LogOutButton
        onClick={() => {
          logOut();
          toggleDropdown();
        }}
      >
        <FaSignOutAlt />
        Log Out
      </LogOutButton>

      <HamburgerMenu onClick={toggleDropdown}>
        <div />
        <div />
        <div />
      </HamburgerMenu>

      {/* Mobile Navigation Dropdown */}
      <MobileNavDropdown isOpen={isDropdownOpen}>
        <CloseIcon onClick={toggleDropdown} />
        <NavList>
          <NavItem
            onClick={() => {
              navigate('/dashboard');
              toggleDropdown();
            }}
          >
            Dashboard
          </NavItem>
          <NavItem
            onClick={() => {
              navigate('/projects');
              toggleDropdown();
            }}
          >
            Projects
          </NavItem>
          <NavItem
            onClick={() => {
              navigate('/habits');
              toggleDropdown();
            }}
          >
            Habits
          </NavItem>
          <NavItem
            onClick={() => {
              navigate('/calendar');
              toggleDropdown();
            }}
          >
            Calendar
          </NavItem>
          <NavItem
            onClick={() => {
              navigate('/settings');
              toggleDropdown();
            }}
          >
            Settings
          </NavItem>
        </NavList>
        <LogOutButton
          onClick={() => {
            logOut();
            toggleDropdown();
          }}
        >
          <FaSignOutAlt />
          Log Out
        </LogOutButton>
      </MobileNavDropdown>
    </SidebarContainer>
  );
};

export default NavigationSidebar;
