import styled from 'styled-components';

export const StyledPageNotFoundContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100vh;
`;

export const StyledPageNotFoundColumnLeft = styled.div`
  flex: 1;
`;

export const StyledPageNotFoundColumnRight = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const StyledPageNotFoundBackground = styled.img`
  width: 100%;
  height: 100%;
`;

export const StyledPageNotFoundTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  font-family: 'Roboto', sans-serif;
  font-style: italic;
`;

export const StyledPageNotFoundTitleText = styled.h6`
  font-size: 200px;
  margin: 0;
  font-weight: lighter;
  letter-spacing: 20px;
`;

export const StyledPageNotFoundSubtitleText = styled.h2`
  font-size: 15px;
  font-weight: lighter;
  margin: 0;
`;

export const GoHomeButton = styled.button`
  background-color: ${(props) => props.theme.colors.primary};
  color: white;
  padding: 10px 30px;
  border: none;
  border-radius: 30px;
  font-size: 15px;
  margin-top: 30px;
  font-style: italic;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;
