import styled from 'styled-components';
import { device } from '../../../../themes/breakpoints';

export const GraphContainer = styled.div`
  width: 50%;
  border-radius: 5px;
  background-color: ${(props) => props.theme.colors.secondaryBackground};
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);

  @media ${device.sm} {
    width: 100%;
  }
`;

export const GraphHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const GraphTitle = styled.p`
  font-weight: bold;
  margin-left: 10px;
`;

export const GraphSwitchDataButton = styled.button`
  margin: 10px;
  border: none;
  background-color: transparent;
  cursor: pointer;
`;
