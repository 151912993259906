export const habitData = [
  {
    title: '🍳 Breakfast',
    estimatedTime: 30,
    daysOfWeek: null,
    intervalCount: 1,
    intervalType: 'daily',
  },
  {
    title: '🥪 Lunch',
    estimatedTime: 30,
    daysOfWeek: null,
    intervalCount: 1,
    intervalType: 'daily',
  },
  {
    title: '🍕 Dinner',
    estimatedTime: 30,
    daysOfWeek: null,
    intervalCount: 1,
    intervalType: 'daily',
  },
  {
    title: '💪 Gym',
    estimatedTime: 60,
    daysOfWeek: 'Monday,Tuesday,Wednesday,Thursday,Friday',
    intervalCount: 1,
    intervalType: 'weekly',
  },
  {
    title: '📚 Reading',
    estimatedTime: 60,
    daysOfWeek: null,
    intervalCount: 1,
    intervalType: 'daily',
  },
  {
    title: '🧘 Meditation',
    estimatedTime: 30,
    daysOfWeek: null,
    intervalCount: 1,
    intervalType: 'daily',
  },
  {
    title: '🧑‍🏫 Learn something',
    estimatedTime: 30,
    daysOfWeek: null,
    intervalCount: 1,
    intervalType: 'daily',
  },
  {
    title: '💻 Coding',
    estimatedTime: 60,
    daysOfWeek: null,
    intervalCount: 1,
    intervalType: 'daily',
  },
  {
    title: '📑 Homework',
    estimatedTime: 60,
    daysOfWeek: null,
    intervalCount: 1,
    intervalType: 'daily',
  },
  {
    title: '😴 Nap',
    estimatedTime: 30,
    daysOfWeek: null,
    intervalCount: 1,
    intervalType: 'daily',
  },
  {
    title: '🚶 Take a walk',
    estimatedTime: 30,
    daysOfWeek: null,
    intervalCount: 1,
    intervalType: 'daily',
  },
  {
    title: '🏃 Take a run',
    estimatedTime: 30,
    daysOfWeek: null,
    intervalCount: 1,
    intervalType: 'daily',
  },
  {
    title: '😌 Relax',
    estimatedTime: 60,
    daysOfWeek: null,
    intervalCount: 1,
    intervalType: 'daily',
  },
  {
    title: '🎮 Video games',
    estimatedTime: 60,
    daysOfWeek: null,
    intervalCount: 1,
    intervalType: 'daily',
  },
  {
    title: '🚗 Travel',
    estimatedTime: 30,
    daysOfWeek: null,
    intervalCount: 1,
    intervalType: 'daily',
  },
  {
    title: '🏊 Swim',
    estimatedTime: 60,
    daysOfWeek: null,
    intervalCount: 1,
    intervalType: 'daily',
  },
];
