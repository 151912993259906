import { DragEvent, useCallback, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../store/store';
import { reorderProjects } from '../api/projects';
import {
  reorderProjectsReduxState,
  selectProjects,
} from '../features/projects/projectsSlice';
import { Projects } from '../models/projects';
import { selectUser } from '../features/user/userSlice';

const useReOrderable = () => {
  const dispatch = useAppDispatch();
  const { id } = useAppSelector(selectUser);
  const projects = useAppSelector(selectProjects);
  const [draggedItemId, setDraggedItemId] = useState<string | null>(null);
  const handleDragStart = (e: DragEvent<HTMLDivElement>, projectId: string) => {
    setDraggedItemId(projectId);
    e.dataTransfer.effectAllowed = 'move';
  };

  const setProjectsOrderValue = (projectsToUpdate: Projects) => {
    return projectsToUpdate.map((project, index) => ({
      ...project,
      order: index,
    }));
  };

  const changeOrder = useCallback(
    (e: DragEvent<HTMLDivElement>, projectId: string, dropped: boolean) => {
      e.preventDefault();
      const draggedItemIndex = projects.findIndex(
        (project) => project.id === draggedItemId,
      );
      const dropTargetIndex = projects.findIndex(
        (project) => project.id === projectId,
      );

      const partiallyUpdatedProjects = [...projects];
      const [reorderedItem] = partiallyUpdatedProjects.splice(
        draggedItemIndex,
        1,
      );
      partiallyUpdatedProjects.splice(dropTargetIndex, 0, reorderedItem);
      const fullyUpdatedProjects = setProjectsOrderValue(
        partiallyUpdatedProjects,
      );

      if (dropped) {
        dispatch(
          reorderProjects({ userId: id, projects: fullyUpdatedProjects }),
        );
      } else {
        dispatch(reorderProjectsReduxState(fullyUpdatedProjects));
      }
    },
    [dispatch, id, projects, draggedItemId],
  );

  const handleDragOver = useCallback(
    (e: DragEvent<HTMLDivElement>, highlightedProjectId: string) => {
      changeOrder(e, highlightedProjectId, false);
    },
    [dispatch, id, projects, draggedItemId],
  );

  const handleDrop = (e: DragEvent<HTMLDivElement>, dropTargetId: string) => {
    changeOrder(e, dropTargetId, true);
    setDraggedItemId(null);
  };

  return {
    draggedItemId,
    handleDragStart,
    handleDragOver,
    handleDrop,
  };
};

export default useReOrderable;
