import { enUS } from 'date-fns/locale/en-US';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import { useTheme } from 'styled-components';
import {
  format,
  parse,
  startOfWeek,
  getDay,
  addDays,
  endOfWeek,
  isSameDay,
  startOfDay,
  endOfDay,
} from 'date-fns';
import { useEffect, useState } from 'react';
import { SubTasks } from '../../../models/subTasks';
import { Tasks } from '../../../models/Tasks';
import { Habits } from '../../../models/habits';
import {
  CalendarEvent,
  generateCalendarEvents,
} from './helpers/calendarEventGeneration';

const locales = {
  'en-US': enUS,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

interface UserCalendarProps {
  timeTrackerData?: CalendarEvent[];
  habits: Habits;
  tasks: Tasks;
  subTasks: SubTasks;
  dayViewOnly?: boolean;
  scrollToTime?: Date;
}

const UserCalendar = ({
  timeTrackerData,
  habits,
  tasks,
  subTasks,
  dayViewOnly,
  scrollToTime,
}: UserCalendarProps) => {
  const theme = useTheme();
  const [start, setStart] = useState(addDays(startOfWeek(new Date()), -1));
  const [end, setEnd] = useState(addDays(endOfWeek(new Date()), 1));
  const [events, setEvents] = useState<CalendarEvent[]>([]);

  useEffect(() => {
    const generatedEvents = generateCalendarEvents(
      habits,
      tasks,
      subTasks,
      start,
      end,
    );
    if (timeTrackerData) {
      setEvents([...timeTrackerData, ...generatedEvents]);
    } else {
      setEvents(generatedEvents);
    }
  }, [habits, tasks, subTasks, start, end]);

  const handleRangeChange = (
    range: Date[] | { start: Date; end: Date },
    view: string | undefined,
  ) => {
    if (view === 'agenda' || view === 'day') {
      setStart(startOfDay(new Date()));
      setEnd(endOfDay(new Date()));
    } else if (Array.isArray(range)) {
      setStart(range[0]);
      setEnd(addDays(range[range.length - 1], 1));
    } else {
      const { start, end } = range;
      setStart(start);
      setEnd(addDays(end, 1));
    }
  };

  const dayPropGetter = (date: Date) => ({
    style: {
      backgroundColor: isSameDay(date, new Date()) ? '#f1f1f1' : 'white',
    },
  });

  const eventPropGetter = () => ({
    style: {
      backgroundColor: theme.colors.primary,
      color: 'white',
      fontSize: '12px',
    },
  });

  const formats = {
    agendaTimeRangeFormat: ({ start, end }: { start: Date; end: Date }) =>
      `${format(start, 'hh:mm:ss a')} - ${format(end, 'hh:mm:ss a')}`,
    agendaHeaderFormat: (range: { start: Date; end: Date }) => {
      return `${format(range.start, 'MM/dd/yyyy')}`;
    },
  };

  return (
    <Calendar
      localizer={localizer}
      events={events}
      startAccessor="start"
      endAccessor="end"
      style={{
        height: dayViewOnly ? 275 : 'calc(100vh - 10px)',
        width: '99%',
        color: theme.colors.primary,
      }}
      dayPropGetter={dayPropGetter}
      eventPropGetter={eventPropGetter}
      onRangeChange={(range, view) => handleRangeChange(range, view)}
      defaultView="week"
      view={dayViewOnly ? 'day' : undefined}
      views={dayViewOnly ? ['day'] : undefined}
      components={dayViewOnly ? { toolbar: () => null } : undefined}
      scrollToTime={scrollToTime}
      formats={formats}
    />
  );
};

export default UserCalendar;
