import styled from 'styled-components';
import { device } from '../../../themes/breakpoints';

export const StyledInfoContainer = styled.div`
  background-color: ${(props) => props.theme.colors.secondaryBackground};
  min-width: 350px;
  width: 100%;
  align-self: flex-start;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  height: 100%;
  max-height: calc(100vh - 20px);

  @media ${device.sm} {
    width: 100%;
    margin-right: 0;
  }
`;
