import { StyledInfoContainer } from './Styles';

interface InfoContainerProps {
  children: React.ReactNode;
  className?: string;
}

const InfoContainer = ({ children, className }: InfoContainerProps) => {
  return (
    <StyledInfoContainer className={className}>{children}</StyledInfoContainer>
  );
};

export default InfoContainer;
