import styled from 'styled-components';

export const HabitRecurrenceSettingContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  gap: 20px;
`;

export const HabitRecurrenceSettingRow = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`;

export const StyledTextContainer = styled.div`
  flex: 3;
  width: 100%;
  white-space: nowrap;
`;

export const StyledCounterContainer = styled.div`
  flex: 1;
  width: 100%;
`;

export const StyledCounterInput = styled.input`
  border: 1px solid ${(props) => props.theme.colors.primaryTextBoxBorder};
  border-radius: 7px;
  background-color: ${(props) => props.theme.colors.primaryTextBoxBackground};
  height: 40px;
  min-width: 50px;
  text-align: center;
  width: 100%;
`;

export const StyledIntervalSelect = styled.select`
  border: 1px solid ${(props) => props.theme.colors.primaryTextBoxBorder};
  border-radius: 7px;
  background-color: ${(props) => props.theme.colors.primaryTextBoxBackground};
  height: 40px;
  flex: 5;
  width: 100%;
`;

export const StyledWeeklyDayOptionsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
`;
