import { z } from 'zod';

export const UserSchema = z.object({
  isAuthenticated: z.boolean(),
  id: z.string(),
  email: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  createdAt: z.string(),
});

export type User = z.infer<typeof UserSchema>;

export const UsersSchema = z.array(UserSchema);

export type Users = z.infer<typeof UsersSchema>;
