import { StyledYellowButton } from './Styles';

interface YellowButtonProps {
  onClick?: () => void;
  title?: string;
  editMode?: boolean;
  className?: string;
}

const YellowButton = ({
  onClick,
  title,
  editMode,
  className,
}: YellowButtonProps) => {
  return (
    <StyledYellowButton
      className={className}
      onClick={onClick ? () => onClick() : undefined}
      type="submit"
      value={title || (editMode ? 'Save' : 'Create')}
    />
  );
};

export default YellowButton;
