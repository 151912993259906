import { configureStore } from '@reduxjs/toolkit';
import { userSlice } from '../features/user/userSlice';
import { projectsSlice } from '../features/projects/projectsSlice';
import { tasksSlice } from '../features/tasks/tasksSlice';
import { subTasksSlice } from '../features/subTasks/subTasksSlice';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../features/RootState';
import { habitsSlice } from '../features/habits/habitsSlice';
import { habitCompletionsSlice } from '../features/habitCompletions/habitCompletionsSlice';

export const store = configureStore({
  reducer: {
    userState: userSlice.reducer,
    projectsState: projectsSlice.reducer,
    habitsState: habitsSlice.reducer,
    habitCompletionsState: habitCompletionsSlice.reducer,
    tasksState: tasksSlice.reducer,
    subTasksState: subTasksSlice.reducer,
  },
});

export type AppDispatch = typeof store.dispatch;

type DispatchFunction = () => AppDispatch;

export const useAppDispatch: DispatchFunction = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
