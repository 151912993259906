import {
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {
  GraphContainer,
  GraphHeader,
  GraphSwitchDataButton,
  GraphTitle,
} from './Styles';
import {
  fillMonthGraphData,
  fillWeekGraphData,
} from '../../helpers/statGraphFunctions';
import { Tasks } from '../../../../models/Tasks';
import { SubTasks } from '../../../../models/subTasks';
import { useTheme } from 'styled-components';
import { useState } from 'react';
import { HabitCompletions } from '../../../../models/habitCompletions';

interface StatsGraphProps {
  habitCompletions: HabitCompletions;
  tasks: Tasks;
  subTasks: SubTasks;
}

const StatsGraph = ({ habitCompletions, tasks, subTasks }: StatsGraphProps) => {
  const [dateIntervalIsMonth, setDateIntervalIsMonth] = useState(false);

  const theme = useTheme();

  return (
    <GraphContainer>
      <GraphHeader>
        <GraphTitle>
          Tasks Completed & Estimated Time Worked /{' '}
          {dateIntervalIsMonth ? 'Month' : 'Day'}
        </GraphTitle>
        <GraphSwitchDataButton
          onClick={() => setDateIntervalIsMonth(!dateIntervalIsMonth)}
        >
          See {dateIntervalIsMonth ? 'Tasks by Day' : 'Tasks by Month'}
        </GraphSwitchDataButton>
      </GraphHeader>
      <ResponsiveContainer width="100%" height={300}>
        <LineChart
          margin={{ left: 0, bottom: 10, right: 40, top: 10 }}
          data={
            dateIntervalIsMonth
              ? fillMonthGraphData(habitCompletions, tasks, subTasks)
              : fillWeekGraphData(habitCompletions, tasks, subTasks)
          }
        >
          <XAxis dataKey="dates" tick={{ fontSize: '12px' }} />
          <YAxis tick={{ fontSize: '12px' }} />
          <Tooltip />
          <Legend wrapperStyle={{ fontSize: '12px' }} />
          <Line
            name="Tasks Completed"
            type="monotone"
            dataKey="tasksCompleted"
            stroke={theme.colors.primary}
            activeDot={{ r: 8 }}
          />
          <Line
            name="Estimated Time Worked (hrs)"
            type="monotone"
            dataKey="estimatedTimeWorked"
            stroke="red"
            activeDot={{ r: 8 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </GraphContainer>
  );
};

export default StatsGraph;
